import React, { useContext, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { logEvent, postRequest, debounce } from "lingoflix-shared/src/utils.js";
import Page from "./layout/Page.js";
import BackButton from "./atoms/BackButton.js";
import { SettingsContext } from "./ContextProvider.js";
import Header from "./layout/Header.js";
import Row from "./layout/Row.js";
import LanguageDropdown from "./atoms/LanguageDropdown.js";
import { toast } from "react-toastify";

const defaultSettings = {
  languages: ["English"],
  allowedContent: [],
  globalRankThreshold: 2000,
  episodeRankPercentileThreshold: 10,

  pauseBetweenScenes: true,
  pauseBetweenScenesNoSubtitles: false,

  showEnglishSentenceTranslation: true,

  showWordPronunciations: true,
  wordPronunciationType: "romaji",

  showJapaneseSubtitles: true,
  showEnglishWordTranslations: true,
  showKanjiTranslations: true,

  ankiServer: "lingoflix",
  ankiCustomUrl: "",
  ankiUsername: "",
  ankiPassword: "",
};

// Dictionary of field types for determining immediate vs. debounced updates
const fieldTypes = {
  pauseBetweenScenes: "boolean",
  pauseBetweenScenesNoSubtitles: "boolean",
  showEnglishSentenceTranslation: "boolean",
  showWordPronunciations: "boolean",
  showJapaneseSubtitles: "boolean",
  showEnglishWordTranslations: "boolean",
  showKanjiTranslations: "boolean",

  ankiServer: "choice",
  wordPronunciationType: "choice",

  ankiCustomUrl: "text",
  ankiUsername: "text",
  ankiPassword: "text",

  globalRankThreshold: "number",
  episodeRankPercentileThreshold: "number",

  languages: "array",
  allowedContent: "array",
};

const settingDependencies = {
  pauseBetweenScenesNoSubtitles: ["pauseBetweenScenes"],
  wordPronunciationType: ["showWordPronunciations"],
  showEnglishWordTranslations: ["showJapaneseSubtitles"],
  showKanjiTranslations: ["showJapaneseSubtitles"],
  ankiCustomUrl: ["ankiServer"],
  ankiUsername: ["ankiServer"],
  ankiPassword: ["ankiServer"],
};

export function isSettingAllowed(settings, key) {
  if (key === "ankiCustomUrl") {
    return getSetting(settings, "ankiServer") === "custom";
  }
  if (key === "ankiUsername" || key === "ankiPassword") {
    const serverType = getSetting(settings, "ankiServer");
    return serverType === "ankiweb" || serverType === "custom";
  }
  return (settingDependencies[key] ?? []).every((dep) =>
    getSetting(settings, dep),
  );
}

export function needsAnkiCustomUrl(settings) {
  return getSetting(settings, "ankiServer") === "custom";
}

export function needsExternalAnkiCredentials(settings) {
  const serverType = getSetting(settings, "ankiServer");
  return serverType === "ankiweb" || serverType === "custom";
}

// Note: use this getter to access settings values in other components. That way
// the defaults will respect the defaults in the defaultSettings object, and
// dependencies will be accounted for (if some other setting needed to be
// enabled first, the dependent setting will be considered false.)
export function getSetting(settings, key) {
  if (!isSettingAllowed(settings, key)) {
    return false;
  }
  return settings?.[key] ?? defaultSettings[key];
}

const SettingsContainer = styled.div`
  margin: auto;
  max-width: 600px;
  padding: 20px;
  color: ${({ theme }) => theme.text};
`;

const LanguageList = styled.ul`
  list-style-type: none;
  padding: 0;
`;

const LanguageItem = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

const DeleteButton = styled.button`
  background-color: #ff4136;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
`;

const SectionTitle = styled.h2`
  margin-top: 2rem;
  margin-bottom: 1rem;
`;

const SubSectionTitle = styled.h3`
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
`;

const StyledCheckbox = styled.input`
  margin-right: 1rem;
`;

const CheckboxLabel = styled.label`
  cursor: pointer;
`;

const RadioContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
`;

const RadioItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
`;

const StyledRadio = styled.input`
  margin-right: 1rem;
`;

const InputLabel = styled.label`
  margin-right: 1rem;
  text-align: center;
`;

const Input = styled.input`
  padding: 0.5rem;
  border: 1px solid ${({ theme }) => theme.border};
  border-radius: 0.25rem;
`;

const Button = styled.button`
  padding: 0.5rem 1rem;
  background-color: ${({ theme }) => theme.primary || "#4a90e2"};
  color: ${({ theme }) => theme.buttonText || "#ffffff"};
  border: 1px solid ${({ theme }) => theme.border || "#2a2a2a"};
  border-radius: 0.25rem;
  cursor: pointer;
  font-size: 0.9rem;
  transition: background-color 0.2s;

  &:hover {
    background-color: ${({ theme }) => theme.primaryDark || "#3a80d2"};
  }

  &:disabled {
    background-color: ${({ theme }) => theme.disabledBg || "#555555"};
    cursor: not-allowed;
  }
`;

export default function Settings() {
  const navigate = useNavigate();
  const [settings, setSettings] = useContext(SettingsContext);

  const languages = getSetting(settings, "languages");
  const allowedContent = getSetting(settings, "allowedContent");

  const headerLeftContent = (
    <Row $gap="1rem">
      <BackButton onClick={() => navigate("/")} />
    </Row>
  );
  const headerCenterContent = <h1>Settings</h1>;

  const handleUpdateSettings = async (field, value, description) => {
    const updatedSettings = { ...settings, [field]: value };
    setSettings(updatedSettings);
    try {
      await postRequest("/api/settings/update", updatedSettings, null, true);

      setSettings(updatedSettings);
      toast.success(`${description} updated successfully`);
      return true;
    } catch (error) {
      console.error("Error updating settings:", error);
      toast.error(`Failed to update ${description}. Please try again.`);
      return false;
    }
  };

  // Create a silent version of update settings (no toast notifications)
  const silentUpdateSettings = async (field, value, description) => {
    const updatedSettings = { ...settings, [field]: value };
    try {
      await postRequest("/api/settings/update", updatedSettings, null, true);
      return true;
    } catch (error) {
      console.error("Error updating settings:", error);
      toast.error(`Failed to update ${description}. Please try again.`);
      return false;
    }
  };

  // Create debounced versions of update functions (without success toasts)
  const debouncedUpdateSettings = useCallback(
    debounce(silentUpdateSettings, 500),
    [settings],
  );

  const handleAddLanguage = async (selectedLanguage) => {
    selectedLanguage = selectedLanguage.name;
    if (selectedLanguage && !languages.includes(selectedLanguage)) {
      const newLanguages = [...languages, selectedLanguage];
      const result = await handleUpdateSettings(
        "languages",
        newLanguages,
        `Support for ${selectedLanguage}`,
      );
      if (result) {
        logEvent("add-language", { language: selectedLanguage });
      }
    }
  };

  const validatePositiveInteger = (event) => {
    const value = event.target.value;
    const parsedValue = parseInt(value, 10);
    if (isNaN(parsedValue) || parsedValue <= 0) {
      event.target.style.color = "red";
      toast.error("Setting must be a positive integer");
      return null;
    }
    event.target.style.color = "black";
    return parsedValue;
  };

  const handleUpdateGlobalRankThreshold = async (event) => {
    const value = validatePositiveInteger(event);
    if (value === null) {
      return true;
    }

    // Update the local state immediately for UI feedback
    setSettings((settings) => ({
      ...settings,
      globalRankThreshold: value,
    }));

    const result = await debouncedUpdateSettings(
      "globalRankThreshold",
      value,
      "Global Rank Threshold",
    );
    if (result) {
      logEvent("update-global-rank-threshold", { value });
    }
  };

  const handleUpdateEpisodeRankPercentileThreshold = async (event) => {
    const value = validatePositiveInteger(event);
    if (value === null) {
      return true;
    }

    // Update the local state immediately for UI feedback
    setSettings((settings) => ({
      ...settings,
      episodeRankPercentileThreshold: value,
    }));

    const result = await debouncedUpdateSettings(
      "episodeRankPercentileThreshold",
      value,
      "Episode Rank Percentile Threshold",
    );
    if (result) {
      logEvent("update-episode-rank-percentile-threshold", { value });
    }
  };

  const handleDeleteLanguage = async (language) => {
    const newLanguages = languages.filter((lang) => lang !== language);
    const result = await handleUpdateSettings(
      "languages",
      newLanguages,
      `Support for ${language}`,
    );
    if (result) {
      logEvent("delete-language", { language });
    }
  };

  const handleContentChange = async (service) => {
    const newAllowedContent = allowedContent.includes(service)
      ? allowedContent.filter((item) => item !== service)
      : [...allowedContent, service];
    const result = await handleUpdateSettings(
      "allowedContent",
      newAllowedContent,
      `Content ownership for ${service}`,
    );
    if (result) {
      logEvent("update-content-ownership", {
        service,
        checked: newAllowedContent.includes(service),
      });
    }
  };

  useEffect(() => {
    document.title = "Settings - LingoFlix";
  }, []);

  const updateSettingIfAllowed = (field, value, description) => {
    if (isSettingAllowed(settings, field)) {
      const fieldType = fieldTypes[field] || "text";

      // Use immediate update for boolean and choice fields
      if (fieldType === "boolean" || fieldType === "choice") {
        handleUpdateSettings(field, value, description);
      } else {
        // For text, number and array inputs, use debounced update
        // Update local state immediately
        setSettings((settings) => ({
          ...settings,
          [field]: value,
        }));

        // Debounce the API call
        debouncedUpdateSettings(field, value, description);
      }
    } else {
      // Note: this should never happen; forbidden settings should be behind
      // `GatedSubsection`s for their dependencies which prevent them from
      // being clickable.
      console.error(
        `Update of ${description} not allowed because of dependencies`,
      );
    }
  };

  const testAnkiConnection = async () => {
    try {
      await postRequest("/anki-export/test-connection", {}, (data) => {
        if (data.success) {
          toast.success(
            `Successfully connected to ${data.serverType} Anki sync server`,
          );
          logEvent("anki-test-connection-success", {
            serverType: data.serverType,
          });
        } else {
          toast.error(
            `Failed to connect to Anki sync server: ${
              data.error || "Unknown error"
            }`,
          );
          logEvent("anki-test-connection-failure", { error: data.error });
        }
        return data;
      });
    } catch (error) {
      console.error("Error testing Anki connection:", error);
      toast.error(`Failed to connect to Anki sync server: ${error}`);
      logEvent("anki-test-connection-failure", { error: String(error) });
    }
  };

  const makeSettingsCheckbox = (field, description) => (
    <CheckboxContainer>
      <StyledCheckbox
        type="checkbox"
        id={field}
        checked={getSetting(settings, field)}
        onChange={() =>
          updateSettingIfAllowed(
            field,
            !getSetting(settings, field),
            description,
          )
        }
      />
      <CheckboxLabel htmlFor={field}>{description}</CheckboxLabel>
    </CheckboxContainer>
  );

  const makeSettingsRadio = (field, description, options) => (
    <RadioContainer>
      {options.map((option) => (
        <RadioItem key={option.code}>
          <StyledRadio
            type="radio"
            id={`${field}_${option.code}`}
            name={field}
            value={option.code}
            checked={getSetting(settings, field) === option.code}
            onChange={() =>
              updateSettingIfAllowed(field, option.code, description)
            }
          />
          {option.description}
        </RadioItem>
      ))}
    </RadioContainer>
  );

  const GatedSubsection = ({ gate, children }) => (
    <div
      style={
        isSettingAllowed(settings, gate)
          ? { marginLeft: "3rem" }
          : {
              marginLeft: "3rem",
              opacity: 0.6,
              pointerEvents: "none",
              userSelect: "none",
            }
      }
    >
      {children}
    </div>
  );

  const makeContentConfirmationCheckbox = (service, description) => (
    <CheckboxContainer>
      <StyledCheckbox
        type="checkbox"
        id={service}
        checked={allowedContent.includes(service)}
        onChange={() => handleContentChange(service)}
      />
      <CheckboxLabel htmlFor={service}>{description}</CheckboxLabel>
    </CheckboxContainer>
  );

  return (
    <Page>
      <Header $left={headerLeftContent} $center={headerCenterContent} />
      <SettingsContainer>
        <SectionTitle>Languages</SectionTitle>
        <LanguageList>
          {languages.map((language) => (
            <LanguageItem key={language}>
              {language}
              {language !== "English" && (
                <DeleteButton onClick={() => handleDeleteLanguage(language)}>
                  Delete
                </DeleteButton>
              )}
            </LanguageItem>
          ))}
        </LanguageList>
        <Row $gap="1rem">
          <LanguageDropdown onSelect={handleAddLanguage} />
        </Row>

        <SectionTitle>Frequent Word Highlighting</SectionTitle>
        <Row $gap="1rem" $center>
          <Input
            type="number"
            value={getSetting(settings, "globalRankThreshold")}
            onChange={handleUpdateGlobalRankThreshold}
          />
          <InputLabel>Global Rank Threshold</InputLabel>
        </Row>
        <Row $gap="1rem" $center>
          <Input
            type="number"
            value={getSetting(settings, "episodeRankPercentileThreshold")}
            onChange={handleUpdateEpisodeRankPercentileThreshold}
          />
          <InputLabel>Episode Rank Percentile Threshold</InputLabel>
        </Row>

        <SectionTitle>Playback</SectionTitle>
        {makeSettingsCheckbox("pauseBetweenScenes", "Pause between scenes")}
        <GatedSubsection gate="pauseBetweenScenes">
          {makeSettingsCheckbox(
            "pauseBetweenScenesNoSubtitles",
            "Pause even if there are no subtitles",
          )}
        </GatedSubsection>

        <SectionTitle>Subtitle Display</SectionTitle>
        {makeSettingsCheckbox(
          "showEnglishSentenceTranslation",
          "Show English sentence translation",
        )}
        <SubSectionTitle>Word Display</SubSectionTitle>
        {makeSettingsCheckbox(
          "showWordPronunciations",
          "Show Word Pronunciations",
        )}
        <GatedSubsection gate="showWordPronunciations">
          {makeSettingsRadio("wordPronunciationType", "Pronunciation Type", [
            { code: "kana", description: "in Kana" },
            { code: "romaji", description: "in Romaji" },
          ])}
        </GatedSubsection>

        {makeSettingsCheckbox(
          "showJapaneseSubtitles",
          "Show Japanese Subtitles",
        )}
        <GatedSubsection gate="showJapaneseSubtitles">
          {makeSettingsCheckbox(
            "showEnglishWordTranslations",
            "Show Word Translations",
          )}
          {makeSettingsCheckbox(
            "showKanjiTranslations",
            "Show Kanji Translations",
          )}
        </GatedSubsection>

        <SectionTitle>Anki Integration</SectionTitle>
        {makeSettingsRadio("ankiServer", "Anki Sync Server", [
          { code: "lingoflix", description: "LingoFlix Sync Server" },
          { code: "ankiweb", description: "AnkiWeb account" },
          { code: "custom", description: "Custom URL" },
        ])}

        {needsExternalAnkiCredentials(settings) && (
          <div style={{ marginLeft: "3rem", marginBottom: "1rem" }}>
            <SubSectionTitle>Anki Credentials</SubSectionTitle>
            <Row $gap="1rem" $center style={{ marginBottom: "0.75rem" }}>
              <Input
                type="text"
                value={getSetting(settings, "ankiUsername")}
                onChange={(e) =>
                  updateSettingIfAllowed(
                    "ankiUsername",
                    e.target.value,
                    "Anki Username",
                  )
                }
                placeholder="Username"
                style={{ width: "250px" }}
              />
              <InputLabel>Username</InputLabel>
            </Row>
            <Row $gap="1rem" $center style={{ marginBottom: "0.75rem" }}>
              <Input
                type="password"
                value={getSetting(settings, "ankiPassword")}
                onChange={(e) =>
                  updateSettingIfAllowed(
                    "ankiPassword",
                    e.target.value,
                    "Anki Password",
                  )
                }
                placeholder="Password"
                style={{ width: "250px" }}
              />
              <InputLabel>Password</InputLabel>
            </Row>
          </div>
        )}

        {needsAnkiCustomUrl(settings) && (
          <div style={{ marginLeft: "3rem", marginBottom: "1rem" }}>
            <Row $gap="1rem" $center>
              <Input
                type="text"
                value={getSetting(settings, "ankiCustomUrl")}
                onChange={(e) =>
                  updateSettingIfAllowed(
                    "ankiCustomUrl",
                    e.target.value,
                    "Anki Custom URL",
                  )
                }
                placeholder="https://your-anki-server.com"
                style={{ width: "300px" }}
              />
              <InputLabel>Custom Anki Server URL</InputLabel>
            </Row>
          </div>
        )}

        <div style={{ marginLeft: "3rem", marginTop: "1rem" }}>
          <Button
            onClick={testAnkiConnection}
            style={{
              backgroundColor: "#4a90e2",
              color: "#ffffff",
              fontWeight: "bold",
              boxShadow: "0 1px 3px rgba(0,0,0,0.2)",
            }}
          >
            Test Syncserver Connection
          </Button>
        </div>

        <SectionTitle>Content Access Confirmations</SectionTitle>
        {makeContentConfirmationCheckbox(
          "netflix",
          "I'm a paid subscriber to Netflix",
        )}
        {makeContentConfirmationCheckbox(
          "youtube",
          "I'm a paid subscriber to YouTube",
        )}
      </SettingsContainer>
    </Page>
  );
}
