import React, { useState, useCallback } from "react";
import styled from "styled-components";
import {
  findCharacterSubstring,
  postRequest,
  writeClipboard,
} from "lingoflix-shared/src/utils.js";
import BreakdownButton from "../atoms/BreakdownButton";
import AutoGrowTextArea from "../atoms/AutoGrowTextArea";
import { toast } from "react-toastify";
import { isDesktop } from "react-device-detect";
import MorphemeGrid from "./phrase/MorphemeGrid";

const NonBoldText = styled.p`
  font-weight: normal;
`;

const FeedbackWrapper = styled.div`
  overflow: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
const BottomBarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  margin-top: 1rem;
`;

// Limit the height of the auto grow text area
const TextAreaWrapper = styled.div`
  width: 100%;
`;

const ToggleableBreakdown = styled.div``;

const LargeText = styled.p`
  font-size: 2em;
`;

const toggleSetMembership = (set, item) => {
  const newSet = new Set(set);
  if (newSet.has(item)) {
    newSet.delete(item);
  } else {
    newSet.add(item);
  }
  return newSet;
};

function SelectableFromSet({ selectedSet, setSelectedSet, name, children }) {
  const isSelected = selectedSet.has(name);
  const toggleSelected = useCallback(() => {
    setSelectedSet(toggleSetMembership(selectedSet, name));
  }, [selectedSet, name, setSelectedSet]);
  return (
    <div
      onClick={toggleSelected}
      style={{
        cursor: "pointer",
        backgroundColor: isSelected ? "#e22" : undefined,
      }}
    >
      {children}
    </div>
  );
}

function getTranslations(breakdown) {
  return [
    ...(breakdown.translations || []),
    ...(breakdown.lone_translations || []),
  ];
}

export default function FeedbackPopup({
  episode,
  closePopup,
  boundaries,
  sentenceBreakdown,
  wordBreakdown = null,
  morphemeBreakdown = null,
}) {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [feedback, setFeedback] = useState("");

  const [reportedErrors, setReportedErrors] = useState(new Set());
  const makeErrorKey = (level, original, field, value) =>
    `${level}\t${original.replaceAll("\t", " ")}\t${field}\t${value}`;
  const parseErrorKey = (key) => {
    const [level, original, field, value] = key.split("\t");
    return { level, original, field, value };
  };
  // Level is "sentence" "word" or "morpheme"
  // Original is the original sentence word or morpheme (with tabs replaced with spaces just in case)
  // Field is "pronunciation", "original", or "translation"
  // Value is the particular value.
  // Note: we're using a string representation here because JS doesn't have
  // proper tuples.

  const sendFeedback = async () => {
    setLoading(true);
    setError("");
    try {
      await postRequest(
        "/api/feedback",
        {
          episodeId: episode.id,
          timeMicros: Math.floor((boundaries?.start + boundaries?.end) / 2),
          comment: feedback,
          sentence: sentenceBreakdown?.original ?? "",
          sentenceBreakdown: JSON.stringify(sentenceBreakdown ?? {}),
          sentenceBreakdownIndex: sentenceBreakdown?.index ?? -1,
          word: wordBreakdown?.original ?? undefined,
          wordBreakdown: wordBreakdown
            ? JSON.stringify(wordBreakdown)
            : undefined,
          morpheme: morphemeBreakdown?.original ?? undefined,
          morphemeBreakdown: morphemeBreakdown
            ? JSON.stringify(morphemeBreakdown)
            : undefined,
          reportedErrors: JSON.stringify(
            Array.from(reportedErrors).map(parseErrorKey),
          ),
        },
        (res) => {
          try {
            if (res.success) {
              closePopup();
              toast("✉️ Email sent! Thanks for your feedback!");
            } else if (res.error) {
              console.error(res.error);
              throw new Error(res.error);
            } else {
              throw new Error("Unknown error");
            }
          } catch (err) {
            setError(err.message ? err.message : err);
          }
        },
      );
    } catch (err) {
      setError(err.message ? err.message : err);
    } finally {
      setLoading(false);
    }
  };

  const handleLinkClick = () => {
    writeClipboard(window.location.href);
    toast("Link copied to clipboard");
  };

  const getMorphemePronunciation = useCallback(
    (morpheme) => {
      if (morpheme.pronunciation) {
        return morpheme.pronunciation;
      }
      const morphemeCharacters = findCharacterSubstring(
        wordBreakdown.characters,
        morpheme.original,
      );
      if (!morphemeCharacters) {
        return null;
      }
      return morphemeCharacters.map((x) => x.pronunciation).join("");
    },
    [wordBreakdown],
  );

  const showToggleableBreakdown = useCallback(
    (level, breakdown) => {
      const original = breakdown.original;
      const pronunciation =
        level == "morpheme"
          ? getMorphemePronunciation(breakdown)
          : breakdown.pronunciation;
      return (
        <ToggleableBreakdown>
          <SelectableFromSet
            selectedSet={reportedErrors}
            setSelectedSet={setReportedErrors}
            name={makeErrorKey(level, original, "pronunciation", pronunciation)}
          >
            {pronunciation}
          </SelectableFromSet>
          <SelectableFromSet
            selectedSet={reportedErrors}
            setSelectedSet={setReportedErrors}
            name={makeErrorKey(level, original, "original", original)}
          >
            <LargeText>{original}</LargeText>
          </SelectableFromSet>
          <div>
            {getTranslations(breakdown).map((translation, i) => (
              <SelectableFromSet
                key={i}
                selectedSet={reportedErrors}
                setSelectedSet={setReportedErrors}
                name={makeErrorKey(level, original, "translation", translation)}
              >
                {translation}
              </SelectableFromSet>
            ))}
          </div>
        </ToggleableBreakdown>
      );
    },
    [reportedErrors, setReportedErrors, getMorphemePronunciation],
  );

  const showMorpheme = useCallback(
    (morpheme) => showToggleableBreakdown("morpheme", morpheme),
    [showToggleableBreakdown],
  );

  const field = (name, value) => {
    return value ? (
      <>
        <b>{name}:</b> &nbsp; {value} &nbsp; &nbsp;
      </>
    ) : null;
  };
  return (
    <>
      <FeedbackWrapper>
        <NonBoldText $centerText="false">
          Hello! If you have any feedback about the LingoFlix website or the way
          the current subtitle has been translated or broken down, please let us
          know by writing it in the comment box or by tapping on any of the
          items below to select errors. Cheers!
        </NonBoldText>
        <small onClick={handleLinkClick}>
          {field("Morpheme", morphemeBreakdown?.original)}
          {field("Word", wordBreakdown?.original)}
          {field("Link", window.location.href)}
        </small>
        <b>Freeform Comments:</b>
        <TextAreaWrapper>
          <AutoGrowTextArea
            value={feedback}
            onChange={(e) => setFeedback(e.target.value)}
            focus={isDesktop}
            $fontSize="2em"
          />
        </TextAreaWrapper>
        <b>Optionally, click items to report errors:</b>
        {sentenceBreakdown && (
          <div>
            <b>Sentence:</b>
            {showToggleableBreakdown("sentence", sentenceBreakdown)}
          </div>
        )}
        {wordBreakdown && (
          <div>
            <b>Word:</b>
            {showToggleableBreakdown("word", wordBreakdown)}
          </div>
        )}
        {wordBreakdown && !morphemeBreakdown && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
              width: "fit-content",
            }}
          >
            <b>Morphemes:</b>
            <MorphemeGrid
              breakdown={wordBreakdown}
              showMorpheme={showMorpheme}
            />
          </div>
        )}
        {morphemeBreakdown && (
          <div>
            <b>Morpheme:</b>
            {showMorpheme(morphemeBreakdown)}
          </div>
        )}
      </FeedbackWrapper>
      <BottomBarWrapper>
        {loading && <p>Sending...</p>}
        {!loading && (
          <BreakdownButton className="breakdown-button" onClick={sendFeedback}>
            Send
          </BreakdownButton>
        )}
        {error && <p style={{ color: "red" }}>{error}</p>}
      </BottomBarWrapper>
    </>
  );
}
