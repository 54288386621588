import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  postRequest,
  getRequest,
  binarySearch,
  timestampToMicros,
} from "lingoflix-shared/src/utils.js";
import { SettingsContext, ProgressContext } from "../ContextProvider.js";
import { getSetting } from "../Settings.js";
import BackButton from "../atoms/BackButton.js";
import VideoBackground from "../layout/VideoBackground.js";
import Scene from "./Scene.js";
import styled from "styled-components";
import NumberIcon from "../NumberIcon.js";
import { isIOS } from "react-device-detect";
import IconCompress from "../../assets/icons/compress.svg";
import IconExpand from "../../assets/icons/expand.svg";
import IconSquare from "../../assets/icons/square.svg";
import IconSquareCheck from "../../assets/icons/square-check.svg";
import IconPlay from "../../assets/icons/play.svg";

import videojs from "video.js";
import "video.js/dist/video-js.css";

const VideoContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden; /* Prevent scrollbars */
  z-index: 1;
`;

const BackButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  position: absolute;
  z-index: 2;
  width: 100%;
  min-height: 44px;
  cursor: pointer;
  pointer-events: none;
`;

const TopButtonsShow = styled.div`
  display: flex;
  position: absolute;
  left: ${({ $buttonsAreVisible }) =>
    $buttonsAreVisible
      ? "calc(2rem + 18px + 4rem)"
      : "0"}; // 18px BackButton width & 4rem FullscreenButton width
  width: ${({ $buttonsAreVisible }) =>
    $buttonsAreVisible
      ? "calc(100% - 2rem - 18px - 4rem - 4rem - 58px)"
      : "100%"};
  min-height: calc(2rem + 44px);
  z-index: 2;
  cursor: pointer;
`;

const FullscreenButton = styled.button`
  &.fullscreen-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    pointer-events: all;
    font-size: 2.5rem;
    font-weight: bold;
    color: white;
    outline: none;
  }
`;

const TopBarButtonWrapper = styled.div`
  padding: 1rem;
`;

const PlayButton = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100px;
  height: 100px;
  background: url("${IconPlay}") no-repeat center;
  cursor: pointer;
  z-index: 20;
`;

function parseTimeLiberally(time) {
  if (!time) return false;

  let nonMicrosStr = "";
  let micros = 0;
  if (time.indexOf(".") != -1) {
    let dotSplit = time.split(".");
    if (dotSplit.length != 2) {
      return false;
    }
    nonMicrosStr = dotSplit[0];
    micros = parseInt(dotSplit[1].padEnd(6, "0"));
    if (micros === null) {
      return false;
    }
  } else {
    nonMicrosStr = time;
    micros = 0;
  }

  var components = nonMicrosStr
    .split(":")
    .map((x) => parseInt(x, 10))
    .reverse();
  var seconds = 0;
  for (let i = 0; i < components.length; i++) {
    if (components[i] === null) {
      return false;
    }
    seconds += components[i] * Math.pow(60, i);
  }

  return seconds * 1000000 + micros;
}

function getInt(numberString) {
  if (typeof numberString !== "string" || numberString.includes("_")) {
    return -1;
  }
  try {
    return parseInt(numberString, 10);
  } catch (e) {
    return -1;
  }
}

function FadeIn({ children }) {
  const divRef = useRef(null);
  setTimeout(() => {
    divRef.current.style.opacity = 1;
  }, 10);
  return (
    <div
      style={{ opacity: 0, transition: "opacity 0.5s ease-in-out" }}
      ref={divRef}
    >
      {children}
    </div>
  );
}

function getSceneBoundaries(subtitles, fragments, totalDurationMicros) {
  if (!totalDurationMicros) {
    console.warn(
      "No total duration set, assuming credits scene is 5% longer than the last subtitle",
    );
    // If the total duration is not set, assume the credits scene is 5% the
    // length of the rest of the video.
    totalDurationMicros = Math.floor(
      subtitles[subtitles.length - 1].end * 1.05,
    );
  }
  const subBoundaries = [
    { start: 0, end: subtitles[0].start },
    ...subtitles.map((sub) => ({
      start: sub.start,
      end: sub.end,
    })),
    {
      start: subtitles[subtitles.length - 1].end,
      end: totalDurationMicros,
    },
  ];

  const sceneBoundaries = fragments
    .map((fragment, fragmentIndex) => {
      if (fragment.includes("_")) {
        const [left, right] = fragment.split("_");
        return {
          index: fragmentIndex,
          fragment: fragment,
          start: subBoundaries[parseInt(left)].end,
          end: subBoundaries[parseInt(right)].start,
        };
      }
      return {
        ...subBoundaries[parseInt(fragment)],
        index: fragmentIndex,
        fragment: fragment,
      };
    })
    .map((boundary) => ({
      startSeconds: boundary.start / 1_000_000,
      endSeconds: boundary.end / 1_000_000,
      ...boundary,
    }));
  return sceneBoundaries;
}

// This is how many scenes we render on either side of the active scene
// (increasing this number will make the multi-swiper less responsive, but
// picking 0 would make the adjacent scenes look black until the swipe completes)
const SCENES_RENDERED_ON_EITHER_SIDE = 5;

// This is the number of seconds before the end of a scene that we try to pause (to avoid flickering)
const PAUSE_MARGIN_SECONDS = 0.1;

// How far into a scene do we have to be in order to rewind to the start of the
// scene when we press the left arrow key, rather than just going back to the
// previous scene?
const SCENE_BOUNDARY_REWIND_MARGIN_SECONDS = 0.5;

// When playing a scene, how far forward do we skip to prevent accidentally
// playing before the scene starts (which could cause a flicker if there's a
// scene transition there.
const PLAY_MARGIN_SECONDS = 0.1;

// How much buffer do we need to have in order dismiss the loading spinner?
const BUFFER_AMOUNT_SECONDS = 3.0;

export default function MultiSwiper({
  config,
  episode,
  fragments,
  posterUrls,
  getWordBreakdownKnowledgeState,
  setWordBreakdownKnowledgeState,
  setIsLoading,
}) {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [settings] = useContext(SettingsContext);

  const [sceneToCardCount, setSceneToCardCount] = useState(null);
  const fetchingSceneToCardCount = useRef(false);

  const fragmentsReversed = useMemo(
    () =>
      Object.fromEntries(
        Object.entries(fragments).map(([idx, frag]) => [frag, +idx]),
      ),
    [fragments],
  );

  const isMounted = useRef(true);
  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const initialLoad = useRef(true);
  const mainSwiper = useRef(null);
  const videoElementRef = useRef(null);
  const playerRef = useRef(null);
  const hasPlayFailed = useRef(false);
  const [playFailed, setPlayFailed] = useState(false);
  const sceneEndTimeout = useRef(null);

  const [progress, setProgress] = useContext(ProgressContext);

  const [activeScene, setActiveScene] = useState(null);

  const [subtitlesVisible, setSubtitlesVisible] = useState(true);
  const [popoverState, setPopoverState] = useState(null);
  const [popoverHasScrollbars, setPopoverHasScrollbars] = useState(false);
  const [popupState, setPopupState] = useState(null);
  const [isVisibleTopButtons, setIsVisibleTopButtons] = useState(true);
  const [isFullscreen, setIsFullscreen] = useState(false);

  const [isSeekBarVisible, setIsSeekBarVisible] = useState(false);

  const [activeBreakdown, setActiveBreakdown] = useState(config["default"]);

  const possibleBreakdowns = useMemo(
    () => Object.keys(config["breakdowns"]),
    [config],
  );
  const subtitles = useMemo(
    () => config.breakdowns[activeBreakdown],
    [config, activeBreakdown],
  );
  const sceneBoundaries = useMemo(
    () =>
      getSceneBoundaries(
        subtitles,
        fragments,
        config["total_duration"]
          ? timestampToMicros(config["total_duration"])
          : null,
      ),
    [subtitles, fragments, config],
  );

  const getSubtitle = useCallback(
    (scene) => {
      const defaultSubtitle = { start_time: "00:00:00.000000" };
      if (scene === null) return defaultSubtitle;
      const subIndex = getInt(fragments[scene]) - 1;
      if (subIndex >= 0 && subIndex < subtitles.length) {
        return subtitles[subIndex];
      }
      if (subIndex === -1) {
        return defaultSubtitle;
      }
      const previousSubtitle = subtitles[getInt(fragments[scene - 1]) - 1];
      if (previousSubtitle) {
        return { start_time: previousSubtitle.end_time };
      }
      return defaultSubtitle;
    },
    [fragments, subtitles],
  );

  // Enable video player:
  useEffect(() => {
    // Make sure Video.js player is only initialized once
    if (!playerRef.current) {
      // The Video.js player needs to be initialized on a video element
      const videoElement = videoElementRef.current;

      if (!videoElement) return;
      const options = {
        // Disable all the controls:
        loadingSpinner: false,
        controls: false,
        controlBar: {
          playToggle: false,
          captionsButton: false,
          chaptersButton: false,
          subtitlesButton: false,
          remainingTimeDisplay: false,
          progressControl: {
            seekBar: false,
          },
          fullscreenToggle: false,
          playbackRateMenuButton: false,
        },
        // Make sure the video plays inline on iOS:
        playsinline: true,
        // Enable autoplay:
        autoplay: true,
        responsive: true,
        fluid: true,
        muted: false,
        loop: false,
        sources: [
          {
            src: `/video/${episode.path}/full.mp4`,
            type: "video/mp4",
          },
        ],
      };
      playerRef.current = videojs(videoElement, options);
      playerRef.current.on("playing", handleSceneEnd);
      playerRef.current.on("play", handleSceneEnd);
      playerRef.current.on("ended", () => {
        const videoLength = playerRef.current.duration();
        playerRef.current.pause();
        playerRef.current.currentTime(videoLength - PAUSE_MARGIN_SECONDS / 2);
      });
    }
  }, [videoElementRef]); // eslint-disable-line react-hooks/exhaustive-deps

  const updateSceneEndTimeout = useCallback((fn, delaySeconds) => {
    if (sceneEndTimeout.current) {
      clearTimeout(sceneEndTimeout.current);
    }
    if (fn) {
      sceneEndTimeout.current = setTimeout(fn, delaySeconds * 1000);
    } else {
      sceneEndTimeout.current = null;
    }
  }, []);

  const shouldPauseAtSceneEnd = useCallback(() => {
    const scene = mainSwiper.current.activeIndex;
    if (scene >= fragments.length - 1) return true; // Always pause at the end of the last scene.

    const subtitle = getSubtitle(scene);
    const isEmpty = (subtitle?.words || []).length === 0;
    return isEmpty
      ? getSetting(settings, "pauseBetweenScenesNoSubtitles")
      : getSetting(settings, "pauseBetweenScenes");
  }, [settings, fragments, getSubtitle]);

  // Detect when the scene has ended and swipe to the next scene, or pause if
  // the pause checkbox in the settings is checked.
  // Note: this needs to be updated if we want to enable double speed.
  const handleSceneEnd = () => {
    if (!isMounted.current || !mainSwiper.current || !playerRef.current) return;
    const playing = isVideoPlaying();
    if (!playing) {
      updateSceneEndTimeout(null);
      return;
    }
    const currentTime = playerRef.current.currentTime();
    const activeIndex = mainSwiper.current.activeIndex;
    const sceneEndSeconds = sceneBoundaries[activeIndex]?.endSeconds || 0;

    if (currentTime >= sceneEndSeconds - PAUSE_MARGIN_SECONDS) {
      if (shouldPauseAtSceneEnd()) {
        pause();
      } else {
        slideNext();
        // Note: this triggers a call to play() which will call handleSceneEnd()
        // for the next scene (once the swiper has advanced.)
      }
    } else {
      const secondsRemaining =
        sceneEndSeconds - PAUSE_MARGIN_SECONDS / 2 - currentTime;
      updateSceneEndTimeout(handleSceneEnd, secondsRemaining);
    }
  };

  const play = useCallback(
    (fromStart = false) => {
      const videoElement = videoElementRef.current;
      if (!videoElement) return;
      if (fromStart) {
        videoElement.currentTime = 0;
      }
      handleSceneEnd();
      var playPromise = videoElement.play();

      if (playPromise !== undefined) {
        playPromise
          .then(() => {
            if (hasPlayFailed.current) {
              hasPlayFailed.current = false;
              setPlayFailed(false);
            }
          })
          .catch((error) => {
            console.error(error);
            if (!hasPlayFailed.current) {
              setPlayFailed(true);
              hasPlayFailed.current = true;
            }
          });
      }
    },
    [videoElementRef],
  );

  const pause = useCallback(() => {
    const videoElement = videoElementRef.current;
    if (videoElement) {
      videoElement.pause();
    }
  }, [videoElementRef]);

  const isVideoPlaying = useCallback(() => {
    const video = videoElementRef.current;
    return (
      video &&
      video.currentTime > 0 &&
      !video.paused &&
      !video.ended &&
      video.readyState > 2
    );
  }, [videoElementRef]);

  const togglePlay = useCallback(() => {
    if (isVideoPlaying()) {
      pause();
    } else {
      const shouldPause = shouldPauseAtSceneEnd();
      // If we're near the end of the segment, and we should pause, rewind to the start:
      const bounds = sceneBoundaries?.[activeScene];
      if (
        shouldPause && // If we paused at the end of a segment, we rewind. Otherwise we just resume.
        bounds &&
        playerRef.current.currentTime() >=
          bounds.endSeconds - PAUSE_MARGIN_SECONDS
      ) {
        playerRef.current.currentTime(
          bounds.startSeconds + PLAY_MARGIN_SECONDS,
        );
      }
      play();
    }
  }, [
    isVideoPlaying,
    pause,
    play,
    sceneBoundaries,
    activeScene,
    shouldPauseAtSceneEnd,
  ]);

  useEffect(() => {
    const interval = setInterval(() => {
      const video = videoElementRef.current;
      if (
        !playerRef.current ||
        !mainSwiper.current ||
        !videoElementRef.current ||
        !sceneBoundaries ||
        !video
      ) {
        return;
      }
      if (isVideoPlaying()) {
        setIsLoading(false);
        return;
      }
      if (video.readyState < 3) {
        setIsLoading(true);
        return;
      }
      const activeScene = mainSwiper.current.activeIndex;
      const bounds = sceneBoundaries[activeScene];
      const timeRangesBuffered = playerRef.current.buffered();
      const bufferedRanges = Array.from(
        {
          length: timeRangesBuffered.length,
        },
        (_, i) => ({
          startSeconds: timeRangesBuffered.start(i),
          endSeconds: timeRangesBuffered.end(i),
        }),
      );
      const sceneIsBuffered = bufferedRanges.some(
        (range) =>
          range.startSeconds < bounds.startSeconds ||
          range.endSeconds >
            Math.min(
              bounds.endSeconds,
              playerRef.current.currentTime() + BUFFER_AMOUNT_SECONDS,
            ),
      );
      setIsLoading(!sceneIsBuffered);
    }, 250);
    return () => clearInterval(interval);
  }, [sceneBoundaries, playerRef, isVideoPlaying, setIsLoading]);

  const seekVideoToScene = useCallback(
    (scene) => {
      updateSceneEndTimeout(null); // Cancel any pending scene end.

      const sceneStartSeconds = sceneBoundaries[scene]?.startSeconds || 0;
      const sceneEndSeconds = sceneBoundaries[scene]?.endSeconds || 0;
      if (playerRef.current) {
        const currentTime = playerRef.current.currentTime();
        // Don't to a seek if we're at or almost at right scene (unless
        // we already finished it, in which case rewinding is the right thing to
        // do.)
        if (
          currentTime < sceneStartSeconds - PAUSE_MARGIN_SECONDS ||
          currentTime > sceneEndSeconds - PAUSE_MARGIN_SECONDS
        ) {
          playerRef.current.currentTime(
            sceneStartSeconds + PLAY_MARGIN_SECONDS,
          );
        }
      }
    },
    [mainSwiper, sceneBoundaries],
  );

  const updateProgress = useCallback(() => {
    if (mainSwiper.current === null) return;
    setProgress((progress) => ({
      ...progress,
      [episode.id]: mainSwiper.current.progress,
    }));
    postRequest("/api/progress", {
      episodeId: episode.id,
      progress: mainSwiper.current.progress,
    });
  }, [mainSwiper, setProgress, episode]);

  const rerenderTitleAndUrl = useCallback(
    (scene) => {
      const subtitle = getSubtitle(scene);

      // Update the page title:
      let titleComponents = [episode.title, episode.show.title];
      if (scene) {
        titleComponents.unshift(
          subtitle.start_time.split(".")[0].replace(/^0:/, ""),
        );
      }
      document.title = titleComponents.join(" - ");
      // Update the URL:
      if (subtitle.start_time) {
        window.history.replaceState(
          {},
          "", // See: https://developer.mozilla.org/en-US/docs/Web/API/History/replaceState
          `/watch/${episode.show.url}/${episode.url}?time=${subtitle.start_time}`,
        );
      }
    },
    [episode, getSubtitle],
  );

  const switchToScene = useCallback(
    (scene) => {
      if (scene < 0 || scene >= fragments.length) return;
      if (!mainSwiper.current || scene === activeScene) return;
      setPopupState(null);
      setActiveScene(scene);
      rerenderTitleAndUrl(scene);
      if (mainSwiper.current.activeIndex !== scene) {
        mainSwiper.current.slideTo(scene);
      }
      seekVideoToScene(scene);
      play();
      updateProgress();
    },
    [
      activeScene,
      fragments,
      rerenderTitleAndUrl,
      seekVideoToScene,
      play,
      updateProgress,
    ],
  );

  const switchToSwiperScene = useCallback(() => {
    switchToScene(mainSwiper.current.activeIndex);
  }, [mainSwiper, switchToScene]);

  const slidePrev = useCallback(() => {
    if (mainSwiper.current) {
      const prevScene = mainSwiper.current.activeIndex - 1;
      switchToScene(prevScene);
    }
  }, [mainSwiper, switchToScene]);

  // This handles the case where we've pressed the left arrow.
  const goBack = useCallback(() => {
    if (mainSwiper.current && playerRef.current) {
      const currentTime = playerRef.current.currentTime();
      const sceneStartSeconds =
        sceneBoundaries?.[mainSwiper.current.activeIndex]?.startSeconds || 0;
      if (
        currentTime >=
        sceneStartSeconds + SCENE_BOUNDARY_REWIND_MARGIN_SECONDS
      ) {
        playerRef.current.currentTime(sceneStartSeconds + PLAY_MARGIN_SECONDS);
        play();
      } else {
        slidePrev();
      }
    }
  }, [mainSwiper, sceneBoundaries, slidePrev]);

  const slideNext = useCallback(() => {
    if (mainSwiper.current) {
      const nextScene = mainSwiper.current.activeIndex + 1;
      switchToScene(nextScene);
    }
  }, [mainSwiper, fragments, switchToScene]);

  useEffect(() => {
    if (fragments && !fetchingSceneToCardCount.current) {
      fetchingSceneToCardCount.current = true;
      getRequest(
        "/api/anki/episode_cards_nr_by_scene/" + episode.id,
        {},
        (data) => {
          setSceneToCardCount(
            fragments.map((fragment) => {
              const breakdownIndex = getInt(fragment) - 1;
              return breakdownIndex >= 0 ? data[breakdownIndex] : 0;
            }),
          );
        },
      );
    }
  }, [episode, fragments]);

  const toggleVisibility = () => {
    setIsVisibleTopButtons(!isVisibleTopButtons);
  };
  const toggleFullscreen = () => {
    if (!document.fullscreenElement) {
      setIsFullscreen(true);
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        // for Firefox
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        // for Safari
        document.documentElement.webkitRequestFullscreen();
      } else if (document.documentElement.msRequestFullscreen) {
        // for IE
        document.documentElement.msRequestFullscreen();
      } else {
        setIsFullscreen(false);
      }
    } else {
      setIsFullscreen(false);
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        // for Firefox
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        // for Safari
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        // for IE
        document.msExitFullscreen();
      } else {
        setIsFullscreen(true);
      }
    }
  };

  function createCardCallback(scene) {
    setSceneToCardCount((current) => {
      if (current === null) return null;
      const newCount = current.slice();
      newCount[scene] = (newCount[scene] ?? 0) + 1;
      return newCount;
    });
  }

  function handleBackButton() {
    if (window.history.length > 1) {
      navigate(-1);
    } else {
      navigate(`/browse/${episode.show.url}`);
    }
  }

  // Hide top buttons after 2.5 seconds
  useEffect(() => {
    let timer;
    if (isVisibleTopButtons) {
      timer = setTimeout(() => setIsVisibleTopButtons(false), 2500);
    }

    return () => clearTimeout(timer);
  }, [isVisibleTopButtons]);

  // Set scene on page load based on URL or progress
  useEffect(() => {
    if (mainSwiper.current && initialLoad.current) {
      initialLoad.current = false;
      const startTime = searchParams.get("time");
      const startTimeMicros = parseTimeLiberally(startTime);
      const fragment =
        startTimeMicros !== false &&
        fragments[
          Math.max(
            0,
            binarySearch(sceneBoundaries, startTimeMicros, (x) => x.start).low,
          )
        ];
      const startScene =
        fragment && fragment in fragmentsReversed
          ? fragmentsReversed[fragment]
          : -1;
      // If we have progress for this episode, use it to set the scene.
      const hasProgress =
        progress &&
        episode.id in progress &&
        progress[episode.id] !== null &&
        !isNaN(progress[episode.id]);
      const newScene =
        startScene !== -1
          ? startScene
          : hasProgress
          ? Math.round(progress[episode.id] * fragments.length)
          : 0;
      if (newScene >= 0 && newScene < fragments.length) {
        switchToScene(newScene);
      }
    }
  }, [progress, fragments]);

  const breakdownSwitchOptions = useMemo(() => {
    return possibleBreakdowns.length > 1
      ? possibleBreakdowns.map((name) => ({
          title: name,
          label: (
            <span>
              <img
                src={name === activeBreakdown ? IconSquareCheck : IconSquare}
                width="16"
                height="16"
              />{" "}
              {name}
            </span>
          ),
          action: () => setActiveBreakdown(name),
        }))
      : [];
  }, [possibleBreakdowns, activeBreakdown]);

  return (
    <FadeIn>
      <VideoContainer>
        <VideoBackground>
          <div data-vjs-player>
            <video
              controls={false}
              ref={videoElementRef}
              autoPlay
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                zIndex: 10,
                objectFit: "contain",
                objectPosition: "top",
              }}
            />
          </div>
          {playFailed && <PlayButton onClick={togglePlay}></PlayButton>}
        </VideoBackground>
      </VideoContainer>
      {!popupState?.popup && !popoverState?.breakdown && (
        <>
          <TopButtonsShow
            onClick={toggleVisibility}
            $buttonsAreVisible={isVisibleTopButtons}
          />
          <BackButtonWrapper>
            {isVisibleTopButtons && (
              <TopBarButtonWrapper>
                <BackButton onClick={handleBackButton} />
              </TopBarButtonWrapper>
            )}
            {!isIOS && isVisibleTopButtons && (
              <FullscreenButton
                className="fullscreen-button"
                onClick={toggleFullscreen}
              >
                <img
                  src={isFullscreen ? IconCompress : IconExpand}
                  width="30"
                  height="30"
                />
              </FullscreenButton>
            )}
            <div onClick={toggleVisibility} style={{ width: "100%" }}></div>
            {!!sceneToCardCount?.[activeScene] && (
              <TopBarButtonWrapper
                style={{
                  display: isVisibleTopButtons ? "block" : "none",
                }}
              >
                <NumberIcon
                  number={sceneToCardCount?.[activeScene] ?? 0}
                  onClick={() =>
                    setPopupState({
                      popup: "deck",
                      breakdown: getSubtitle(activeScene),
                    })
                  }
                  style={{ padding: "1rem" }}
                  icon={"cards"}
                />
              </TopBarButtonWrapper>
            )}
            {!isVisibleTopButtons && (
              <div
                style={{
                  cursor: "pointer",
                  pointerEvents: "none",
                  width: "calc(5% + 4.5rem + 6px)",
                }}
              ></div>
            )}
          </BackButtonWrapper>
        </>
      )}
      <Swiper
        direction="horizontal"
        loop={false}
        spaceBetween={30}
        centeredSlides
        keyboard={{ enabled: false }}
        threshold={50}
        onSwiper={(swiper) => {
          mainSwiper.current = swiper;
        }}
        allowSlideNext={!popoverHasScrollbars}
        allowSlidePrev={!popoverHasScrollbars}
        onSlideChange={switchToSwiperScene}
      >
        {fragments.map((f, i) => (
          <SwiperSlide key={i} virtualIndex={i}>
            {Math.abs(i - activeScene) <= SCENES_RENDERED_ON_EITHER_SIDE && (
              <Scene
                play={play}
                pause={pause}
                togglePlay={togglePlay}
                fragment={f}
                subtitle={getSubtitle(i)}
                episode={episode}
                sceneBoundaries={sceneBoundaries}
                index={i}
                posterUrls={posterUrls}
                isActiveScene={i === activeScene}
                popoverState={popoverState}
                setPopoverState={setPopoverState}
                popupState={popupState}
                popoverHasScrollbars={popoverHasScrollbars}
                setPopoverHasScrollbars={setPopoverHasScrollbars}
                setPopupState={setPopupState}
                subtitlesVisible={subtitlesVisible}
                setSubtitlesVisible={setSubtitlesVisible}
                isSeekBarVisible={isSeekBarVisible}
                setIsSeekBarVisible={setIsSeekBarVisible}
                createCardCallback={createCardCallback}
                breakdownMenuOptions={breakdownSwitchOptions}
                getWordBreakdownKnowledgeState={getWordBreakdownKnowledgeState}
                setWordBreakdownKnowledgeState={setWordBreakdownKnowledgeState}
                slidePrev={slidePrev}
                slideNext={slideNext}
                seekToScene={switchToScene}
                goBack={goBack}
              />
            )}
          </SwiperSlide>
        ))}
      </Swiper>
    </FadeIn>
  );
}
