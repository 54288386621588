// Utilities for consistently rendering Anki cards
import { highlightCharacterSubstring } from "./utils.js";

export function getAnkiCSS() {
  return `
.card {
    font-family: Arial, sans-serif;
    border: 1px solid #444;
    /* Darker border color */
    margin: 5px;
    padding: 10px;
    border-radius: 8px;
    background-color: #333;
    /* Dark background for the card */
    color: #ddd;
    /* Light text color for readability */
}

.front,
.back {
    text-align: center;
}

.separator {
    display: inline-block;
    border: none;
    border-top: 1px dashed rgba(127, 127, 127, 0.6);
    width: 100%;
}

.en-main-word {
    font-size: 24px;
    color: #f9f9f9;
    /* Brighter color for main words */
    background: #333;
    border: 1px solid #444;
    border-radius: 0.5rem;
    padding: 1rem;
}

.jp-main-word {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}

.jp-main-word-column {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.jp-main-word-pronunciation {
    font-size: 12px;
    font-weight: bold;
    /* Makes the main word more prominent */
    color: #ccc;
}

.jp-main-word-text {
    position: relative;
    bottom: 0;
    /* Aligns at the bottom */

    font-size: 24px;
    font-weight: bold;
    /* Makes the main word more prominent */
    color: #4db8ff;
    /* Slightly lighter blue for visibility */
}

.word-rank {
    text-align: center;
    font-size: 10px;
    color: #ccc;
}

rt {
    margin-bottom: 0.1em;
}

.muted {
    filter: brightness(0.5);
}

.lit {
    font-weight: bold;
}

.poster {
    width: 70%;
    /* I thought the image was taking up too much space at 100% */
    height: auto;
    border: 1px solid #555;
    /* Darker border for the image */
    border-radius: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
}


.jp-sentence,
.en-translation {
    font-size: 12px;
    color: #ccc;
    /* Slightly lighter gray for text */
}

.jp-sentence {
    margin-bottom: 0;
}

.row {
    display: flex;
    flex-wrap: wrap;
    /* Make the words wrap to the next line if the screen is too narrow. */
    justify-content: center;
    gap: 0.5rem;
}

.word-column {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.word-pronunciation {
    font-size: 10px;
}

.word-text {
    font-size: 12px;
}

.en-translation {
    margin-top: 5px;
    font-style: italic;
    margin-bottom: 10px;
}

.custom-field {
    width: 100%;
    background-color: #2a2a2a;
    /* Dark background for the mnemonics section */
    padding: 8px;
    border-radius: 4px;
    margin-top: 10px;
}

.field-name {
    color: #f0e68c;
    /* Khaki color for visibility and contrast */
    font-weight: bold;
    /* Makes the field name more prominent */
    font-size: 16px;
}

.field-value {
    color: #b0c4de;
    /* Light steel blue for a pleasant reading experience */
    display: block;
    /* Optional: Places the value on a new line */
    margin-left: 4px;
    /* Adds space between the name and the value */
    font-size: 14px;
}

.nowrap {
    white-space: nowrap;
    /* Prevents the text from wrapping */
}

.kanji-list {
    font-size: 10px;
    color: #ccc;
    /* Slightly lighter gray for text */
}

/* Style the audio controls */
.audio-container {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    /* Adds space between the text and the audio player */
}

audio {
    outline: none;
}

audio::-webkit-media-controls-panel {
    border: none;
    background-color: #222;
}

audio::-webkit-media-controls-enclosure {
    background-color: #222;
    border: none;
    /* Remove any border on the enclosure */
}

audio::-webkit-media-controls-current-time-display,
audio::-webkit-media-controls-time-remaining-display {
    color: #fff;
}

/* Style the play/pause button */
audio::-webkit-media-controls-play-button,
audio::-webkit-media-controls-mute-button {
    background-color: #555;
    border-radius: 50%;
}

/* Style the volume slider */
audio::-webkit-media-controls-volume-slider {
    background-color: #555;
    border-radius: 25px;
    padding-left: 8px;
    padding-right: 8px;
}

/* Style the timeline slider */
audio::-webkit-media-controls-timeline {
    background-color: #555;
    border-radius: 25px;
    margin-left: 10px;
    margin-right: 10px;
}
`;
}

function div(divClass, content, title) {
  let titleTag = title ? ` title="${title}"` : "";
  return `<div class="${divClass}"${titleTag}>${content}</div>`;
}
function span(spanClass, content) {
  return `<span class="${spanClass}">${content}</span>`;
}

export function renderRubyWord(rubyBreakdown, rank) {
  if (!rubyBreakdown) return null;
  const romaji = rubyBreakdown.map((word) => word.romaji).join("");
  let result = div(
    "jp-main-word",
    rubyBreakdown
      .map((word) =>
        div(
          "jp-main-word-column",
          div("jp-main-word-pronunciation", word.romaji ?? "&nbsp;") +
          div("jp-main-word-text", word.original),
        ),
      )
      .join(""),
    romaji,
  );
  if (rank) {
    result += div("word-rank", `Word Rank: ${rank}`);
  }
  return result;
}

export function renderKanjis(kanjis) {
  if (!kanjis) return null;
  return `<div class="kanji-list">(${kanjis.map((kanji) => `${kanji.kanji} ${kanji.english}`).join("; ")})</div>`;
}

export function renderPoster(posterUrl) {
  if (!posterUrl) return null;
  return `<img class="poster" src="${posterUrl}" />`;
}

export function renderAudio(audioSrc) {
  if (!audioSrc) return null;
  return `<div class="audio-container"><audio controls src="${audioSrc}">Audio element not supported.</audio></div>`;
}

export function renderSentence(sentenceElements, wordRubyBreakdown, original) {
  if (!sentenceElements) {
    return "";
  }

  // If the wordRubyBreakdown contains undefined romaji, it must have failed to split.
  const hasUndefinedRomaji = (wordRubyBreakdown || [])
    .map((x) => x.romaji)
    .some((x) => x === undefined);

  const wordOriginal = (wordRubyBreakdown || [])
    .map((x) => x.original)
    .join("");
  let html = "";

  const wordColumn = (columnClass, pronunciation, word) =>
    div(
      `word-column gap ${columnClass}`,
      div("word-pronunciation", span("nowrap", pronunciation)) +
      div("word-text", span("nowrap", word)),
    );

  sentenceElements.forEach((lineWords) => {
    html += div(
      "row",
      (lineWords || [])
        .map((word) => {
          const isOriginalWord = word.original === wordOriginal;
          if (isOriginalWord && wordRubyBreakdown && !hasUndefinedRomaji) {
            // In this case we might be doing a partial-word highlight.
            const highlighted = highlightCharacterSubstring(
              wordRubyBreakdown,
              original,
            );
            let wordText = "";
            let pronunciation = "";
            highlighted.forEach((char) => {
              const highlightClass = char.highlight ? "lit" : "muted";
              wordText += span(highlightClass, char.original);
              pronunciation += span(highlightClass, char.romaji);
            });
            return wordColumn("", pronunciation, wordText);
          } else {
            const highlightClass = isOriginalWord ? "lit" : "muted";
            return wordColumn(
              highlightClass,
              word.pronunciation,
              word.original,
            );
          }
        })
        .join(""),
    );
  });
  return div("jp-sentence", html);
}

export function renderSentenceEnTranslation(en_translation) {
  if (!en_translation) return null;
  return `<div class="en-translation">${en_translation}</div>`;
}
