export function logEvent(action, lessonIndex, lessonStart, movieId, details) {
  postRequest("/api/eventLog", {
    action,
    lessonIndex,
    lessonStart,
    movieId,
    details,
  }).catch((error) => {
    console.error("Failed to log event:", error);
  });
}

export async function getRequest(url, data, callback, noJson = false) {
  const params = new URLSearchParams(data);
  const urlWithParams = params.toString() ? `${url}?${params}` : url;

  const response = await fetch(urlWithParams, {
    credentials: "include",
  });

  if (!response.ok) {
    console.error("Get request error", response, "request was:", {
      url,
      data,
      noJson,
    });
    throw response.statusText;
  }

  if (callback) {
    return callback(noJson ? response : await response.json());
  }
  try {
    return noJson ? response : await response.json();
  } catch (e) {
    throw e.message;
  }
}

export async function postRequest(url, body, callback, noJson = false) {
  const response = await fetch(url, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    credentials: "include",
    body: JSON.stringify(body),
  });

  if (!response.ok) {
    console.error("Post request error", response, "request was:", {
      url,
      body,
      noJson,
    });
    try {
      const errorData = await response.json();
      if (errorData && errorData.error) {
        console.error("Post request error data", errorData);
        throw errorData.error;
      }
    } catch (e) {
      if (!(e instanceof SyntaxError)) {
        throw e;
      }
    }
    throw response.statusText;
  }

  if (callback) {
    try {
      return callback(noJson ? response : await response.json());
    } catch (e) {
      throw e.message;
    }
  }
  return response;
}

export async function deleteRequest(url, body, callback, noJson = false) {
  const params = new URLSearchParams(body);
  const urlWithParams = params.toString() ? `${url}?${params}` : url;

  const response = await fetch(urlWithParams, {
    method: "DELETE",
    credentials: "include",
  });

  if (!response.ok) {
    console.error("Delete request error", response, "request was:", {
      url,
      body,
      noJson,
    });
    throw response.statusText;
  }

  if (callback) {
    try {
      return callback(noJson ? response : await response.json());
    } catch (e) {
      throw e.message;
    }
  }
  return response;
}

export function formatDate(date) {
  const z = (number, length) => String(number).padStart(length, "0");

  var datePart =
    z(date.getFullYear(), 4) +
    "-" +
    z(date.getMonth() + 1, 2) +
    "-" +
    z(date.getDate(), 2);
  var timePart =
    z(date.getHours(), 2) +
    ":" +
    z(date.getMinutes(), 2) +
    ":" +
    z(date.getSeconds(), 2);
  return `${datePart} ${timePart}`;
}

export function timestampToMicros(timestamp) {
  const [hours, minutes, secondsAndMicros] = timestamp.split(":");
  const [seconds, micros] = secondsAndMicros.split(".");
  return (
    secondsToMicros((+hours * 60 + +minutes) * 60 + +seconds) +
    (micros ? +micros.slice(0, 6).padEnd(6, "0") : 0)
  );
}

export function microsToTimestamp(micros) {
  const microsecondsInASecond = 1_000_000;
  const secondsInAMinute = 60;
  const minutesInAnHour = 60;

  // Convert microseconds to total seconds
  let totalSeconds = micros / microsecondsInASecond;

  // Extract hours
  const hours = Math.floor(totalSeconds / (minutesInAnHour * secondsInAMinute));
  totalSeconds -= hours * minutesInAnHour * secondsInAMinute;

  // Extract minutes
  const minutes = Math.floor(totalSeconds / secondsInAMinute);
  totalSeconds -= minutes * secondsInAMinute;

  // Remaining seconds are the whole seconds part
  const seconds = Math.floor(totalSeconds);

  // Subtract whole seconds from total seconds to get microseconds part
  const microsPart = Math.round(
    (totalSeconds - seconds) * microsecondsInASecond,
  );

  // Format the timestamp
  return `${padWithZeros(hours, 2)}:${padWithZeros(minutes, 2)}:${padWithZeros(
    seconds,
    2,
  )}.${padWithZeros(microsPart, 6)}`;
}

export function microsToShortTimestamp(micros) {
  let ts = microsToTimestamp(micros);
  ts = ts.split(".")[0]; // Remove micros
  ts = ts.replace(/^[0:]*/, ""); // Remove leading 0 components.
  return ts;
}

function padWithZeros(number, length) {
  return number.toString().padStart(length, "0");
}
export function secondsToMicros(seconds) {
  return Math.round(seconds * 1_000_000);
}

export function microsToSeconds(micros) {
  return micros / 1_000_000;
}

export function containsJapanese(str) {
  if (!str) {
    return false;
  }
  const regex =
    /[\u3040-\u30ff\u3400-\u4dbf\u4e00-\u9fff\uf900-\ufaff\uff66-\uff9f]+/g;
  return regex.test(str);
}

export function containsParathesis(str) {
  if (!str) {
    return false;
  }
  const regex = /\(|\)|（|）/g;
  return regex.test(str);
}

export function isKatakana(char) {
  const code = char.charCodeAt(0);
  // Matches Katakana (U+30A0-U+30FF) and half-width characters (U+FF60-U+FF9F)
  return (
    (0x30a0 <= code && code <= 0x30ff) || (0xff60 <= code && code <= 0xff9f)
  );
}

export function isKanji(c) {
  const code = c.charCodeAt(0);
  return (
    (code >= 0x4e00 && code <= 0x9fff) ||
    (code >= 0x3400 && code <= 0x4dbf) ||
    (code >= 0xf900 && code <= 0xfaff) ||
    c === "々"
  );
}

export function containsKanji(str) {
  return str.split("").some(isKanji);
}

export function displayTranslation(original, translation, limit) {
  const rawTranslation = translation;
  var displayTranslation = translation;
  if (translation.length > limit) {
    displayTranslation = translation.slice(0, Math.max(0, limit - 3)) + "…";
  }
  return {
    original: original,
    rawTranslation: rawTranslation,
    displayTranslation: displayTranslation,
    tooltip: `${original} ${rawTranslation}`,
  };
}

function findCharacterSubstringRange(characters, original) {
  for (let start = 0; start < characters.length; start++) {
    for (let end = start + 1; end <= characters.length; end++) {
      const candidate = characters.slice(start, end);
      if (candidate.map((c) => c.original).join("") == original) {
        return [start, end];
      }
    }
  }
  return null;
}

export function findCharacterSubstring(characters, original) {
  let range = findCharacterSubstringRange(characters, original);
  if (range === null) return null;
  let [start, end] = range;
  return characters.slice(start, end);
}

export function highlightCharacterSubstring(characters, original) {
  let range = findCharacterSubstringRange(characters, original);
  let [start, end] = range ?? [0, 0];
  return characters.map((c, i) => ({ ...c, highlight: i >= start && i < end }));
}

export function timeMicrosOf(breakdown) {
  const startTimeMicros = timestampToMicros(breakdown.start_time);
  const endTimeMicros = timestampToMicros(breakdown.end_time);
  return (startTimeMicros + endTimeMicros) / 2;
}

export async function writeClipboard(text) {
  try {
    await navigator.clipboard.writeText(text);
  } catch (error) {
    console.error(error.message);
  }
}

export function convertToObject(array, key) {
  const result = {};
  array.forEach((item) => {
    result[item[key]] = item;
  });
  return result;
}

export function filterKanji(characters) {
  return (characters || []).filter(
    (k) =>
      k.alphabet === "kanji" && k.translations && k.translations.length > 0,
  );
}

export function debounce(func, wait) {
  let timeout = null;
  let lastCall = 0;
  return (...args) => {
    const now = Date.now();
    if (now - lastCall >= wait) {
      if (timeout) {
        clearTimeout(timeout);
      }
      lastCall = now;
      func(...args);
    } else {
      if (timeout) {
        clearTimeout(timeout);
      }
      timeout = setTimeout(
        () => {
          lastCall = Date.now();
          func(...args);
        },
        wait - (now - lastCall),
      );
    }
  };
}

// Frequency to rank conversion for wordfrequencies from the `wordfreq` library.
const log10FreqToLog10RankCoefs = [-0.78397417, -0.25318639];
// See notebooks/WordFreqRankZipf.ipynb for derivation

function polyEval(coefs, x) {
  return coefs.reduce((acc, coef) => acc * x + coef, 0);
}

function freqToRank(freq) {
  return Math.ceil(10 ** polyEval(log10FreqToLog10RankCoefs, Math.log10(freq)));
}

export function computeGlobalRank(word) {
  if (word.wordrank) return word.wordrank;
  if (word.wordfreq) return freqToRank(word.wordfreq);
  return null;
}

// Does a Binary search through a sorted array. Returns an object with low and
// high indices and whether the value was found exactly.
// Assumes elements of `arr` are monotonically increasing.
// You can optionally provide a `key` function to search on a property of the
// elements.
export function binarySearch(arr, value, key) {
  if (!key) key = (x) => x;

  const point = (n) => ({ low: n, high: n, exact: true });
  const span = (high) => ({ low: high - 1, high, exact: false });
  if (arr.length === 0) return span(0);
  if (value < key(arr[0])) return span(0);
  if (value >= key(arr[arr.length - 1])) return span(arr.length);

  let low = 0;
  let high = arr.length - 1;
  while (low + 1 < high) {
    const mid = Math.floor((low + high) / 2);
    if (key(arr[mid]) === value) return point(mid);
    if (key(arr[mid]) < value) low = mid;
    else high = mid;
  }
  if (key(arr[low]) === value) return point(low);
  if (key(arr[high]) === value) return point(high);
  return span(high);
}

export function escapeRegex(str) {
  return str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
}
