import React, { useMemo, useState } from "react";
import styled from "styled-components";
import Column from "../../layout/Column.js";
import { isMobile } from "react-device-detect";

const Span = styled.p`
  font-size: ${isMobile ? 1.1 : 1.625}rem;
  transition: all 0.5s ease-in-out;
  white-space: nowrap;
  text-align: center;
  transform-origin: center;
  transform: scale(${({ $isBig }) => ($isBig ? 2 : 1)});
  padding: ${({ $isBig }) => ($isBig ? 0.5 : 0)}rem;
  // We increase the padding to make sure the our character doesn't collide with
  // the pronunciation or the edge of the cell when $isBig is set.  (We have to
  // do this separately because CSS transformations don't count when calculating
  // the size of an element for document-flow purposes.)
`;

const Meaning = styled.p`
  font-size: 0.75rem;
  margin: 0;
  color: ${({ $primary }) => !$primary && "rgb(180, 180, 180)"};
  text-align: ${({ $center }) => $center && "center"};
`;

const Grid = styled.div`
  display: grid;
  grid-template-areas: ${({ $gridAreas }) => $gridAreas};
  margin-right: 10px;
  border-right: 2px solid #888888;
  border-bottom: 1px solid #888888;
  margin-bottom: 1px; /* Occasionally the bottom border is cut off, so we add a little extra space */
`;

const GridItem = styled.div`
  grid-area: ${({ $gridArea }) => $gridArea};
  border-left: 2px solid #888888;
  border-top: 1px solid #888888;
  ${({ $underlined }) => $underlined && "border-bottom: 2px solid #888888;"}
  padding: ${isMobile ? 0.35 : 0.7}rem;
  word-wrap: break-word;
`;

// Replaces all dots in a matrix with unique letters, to fill up the grid
function replaceDots(arr) {
  let letter = 97; // 'a'
  return arr.map((subArr) =>
    subArr.map((val) => (val === "." ? String.fromCharCode(letter++) : val)),
  );
}

// In a matrix, expands horizontally adjecent blocks of identical characters downward if the characters below are all dots
// Example: verticallyExpandAreas([["a", "b"], [".", "."]]) returns [["a", "b"], ["a", "b"]]
function verticallyExpandAreas(grid) {
  let expandedGrid = grid.map((subArr) => [...subArr]);
  for (let i = 0; i < expandedGrid.length - 1; i++) {
    for (let j = 0; j < expandedGrid[i].length; j++) {
      if (expandedGrid[i][j] !== ".") {
        const blockEndIndex = findBlockEnd(expandedGrid[i], j);
        if (isVerticallyExpandable(expandedGrid, i, j, blockEndIndex)) {
          expandedGrid = expandBlockDownward(expandedGrid, i, j, blockEndIndex);
        }
        j = blockEndIndex - 1;
      }
    }
  }
  return expandedGrid;
}

// In an array, finds the index of the last identical element starting from a given index
function findBlockEnd(row, startIndex) {
  let endIndex = startIndex;
  while (row[endIndex] === row[startIndex]) endIndex++;
  return endIndex;
}

// In a matrix, returns whether all characters under a block of characters are dots
function isVerticallyExpandable(grid, rowIndex, startIndex, endIndex) {
  return grid[rowIndex + 1]
    .slice(startIndex, endIndex)
    .every((val) => val === ".");
}

// In a matrix, expands a horizontally adjecent block of characters downward
// Example: expandBlockDownward([["a", "a"], [".", "."]], 0, 0, 2) returns [["a", "a"], ["a", "a"]]
function expandBlockDownward(grid, rowIndex, startIndex, endIndex) {
  const expandedGrid = grid.map((subArr) => [...subArr]);
  for (let l = startIndex; l < endIndex; l++)
    expandedGrid[rowIndex + 1][l] = expandedGrid[rowIndex][startIndex];
  return expandedGrid;
}

// Creates a mapping of each character in the original string to its corresponding chunk in the array.
// Example: createIndexToChunkMapping(["Hello", "World"]) returns [0, 0, 0, 0, 0, 1, 1, 1, 1, 1]
function createIndexToChunkMapping(chunks) {
  let indexToChunk = [];
  chunks.forEach((_chunk, i) => {
    for (let j = 0; j < chunks[i].length; j++) {
      indexToChunk.push(i);
    }
  });
  return indexToChunk;
}

// Finds the indices of a substring within an array of strings.
// Example: findSubstringIndices(["Hello", "World"], "loW", [0, 0, 0, 0, 0, 1, 1, 1, 1, 1]) returns [[0, 3], [0, 4], [1, 0]]
function findSubstringIndices(chunks, str, indexToChunk) {
  let original = chunks.join("");
  let result = [];
  let match_start = original.indexOf(str);
  while (match_start != -1) {
    for (let i = 0; i < str.length; i++) {
      result.push([indexToChunk[match_start + i], i]);
    }
    match_start = original.indexOf(str, match_start + str.length);
  }
  return result;
}

// Finds the indices of a substring within an array of strings.
// Example: charIndices(["Hello", "World"], "loW") returns [[0, 3], [0, 4], [1, 0]]
function charIndices(chunks, str) {
  let indexToChunk = createIndexToChunkMapping(chunks);
  return findSubstringIndices(chunks, str, indexToChunk);
}

// Slices an array of size n into n/size chunks of size length
// Example: inGroupsOf([1, 2, 3, 4, 5, 6], 2) returns [[1, 2], [3, 4], [5, 6]]
function inGroupsOf(array, size) {
  let result = [];
  for (let i = 0; i < array.length; i += size) {
    result.push(array.slice(i, i + size));
  }
  return result;
}

// Creates an object with `original` as keys and the morphemes as values
function createOriginalToMorphemeMap(morphemes) {
  return morphemes.reduce((acc, c) => ({ ...acc, [c.original]: c }), {});
}

// Creates a mapping of each morpheme to a unique letter, to avoid the contents of the `original` having incorrect characters for CSS grids
function createMorphemeToLetterMap(morphemes) {
  const alphabet = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
  return morphemes
    .flat()
    .filter((c, i, a) => a.indexOf(c) === i)
    .reduce((acc, c, i) => ({ ...acc, [c]: alphabet[i] }), {});
}

// Turns a matrix of morphemes into a CSS grid of letters, expanding vertically if there's nothing below
function createGrid(morphemes, morphemeToLetterMap) {
  const grid = morphemes.map((subArr, i) =>
    subArr.map((s, j) =>
      s === "." ? s : morphemeToLetterMap[s] + i + (i === 0 ? "_" + j : ""),
    ),
  );
  return replaceDots(verticallyExpandAreas(grid));
}

// Turns an array of arrays of strings into a CSS grid template areas string
function createGridAreas(grid) {
  return grid.map((subArr) => `"${subArr.join(" ")}"`).join("\n");
}

function getCharacters(breakdown) {
  return (
    breakdown.characters ||
    breakdown.original.split("").map((b) => ({ original: b.original }))
  );
}

// Fits morphemes into a grid
function constructMorphemeGrid(breakdown) {
  const morphemes = breakdown.morphemes || [];
  const characters = (
    breakdown.characters ||
    breakdown.original.split("").map((b) => ({ original: b.original }))
  ).map((c) => c.original);
  const gridAreas = [characters];
  let toFit = morphemes
    .filter((m) => breakdown.original.includes(m.original))
    .sort((a, b) => b.original.length - a.original.length);
  while (toFit.length > 0) {
    const takenIndices = [];
    gridAreas.push([...Array(characters.length)].map(() => "."));
    toFit = toFit.filter((m) => {
      const indices = charIndices(characters, m.original);
      const matches = inGroupsOf(indices, m.original.length);
      const matchedChar = matches
        .map((a) => a.map(([i]) => i).filter((i, j, a) => a.indexOf(i) === j))
        .find((a) => a.every((x) => !takenIndices.includes(x)));
      (matchedChar || []).forEach((i) => {
        gridAreas[gridAreas.length - 1].splice(i, 1, m.original);
        takenIndices.push(i);
      });
      return !matchedChar;
    });
  }
  return gridAreas;
}

export default function MorphemeGrid({
  breakdown,
  onClickMorpheme = null,
  showMorpheme = null,
}) {
  const [activeCharacterIndex, setActiveCharacterIndex] = useState(-1);

  const morphemes = useMemo(() => {
    return constructMorphemeGrid(breakdown);
  }, [breakdown]);

  const originalToMorphemeMap = useMemo(
    () => createOriginalToMorphemeMap(breakdown.morphemes || []),
    [breakdown],
  );
  const morphemeToLetterMap = useMemo(
    () => createMorphemeToLetterMap(morphemes),
    [morphemes],
  );
  const grid = useMemo(
    () => createGrid(morphemes, morphemeToLetterMap),
    [morphemes, morphemeToLetterMap],
  );
  const gridAreas = useMemo(() => createGridAreas(grid), [grid]);
  const lacksPronunciations = useMemo(
    () =>
      !breakdown ||
      !breakdown.characters ||
      breakdown.characters.every((c) => !c.pronunciation),
    [breakdown],
  );

  const defaultShowMorpheme = (morpheme) => (
    <>
      {morpheme.translations &&
        morpheme.translations.map((translation, i) => (
          <Meaning key={i} $primary $center>
            {translation}
          </Meaning>
        ))}
      {morpheme.lone_translations &&
        morpheme.lone_translations.map((translation, i) => (
          <Meaning key={i} $center>
            {translation}
          </Meaning>
        ))}
    </>
  );

  const cursorStyle = useMemo(() => {
    if (onClickMorpheme) {
      return "pointer";
    }
    return undefined;
  }, [onClickMorpheme]);

  return (
    <Grid $gridAreas={gridAreas}>
      {getCharacters(breakdown).map((c, i) => (
        <GridItem
          key={i}
          $gridArea={morphemeToLetterMap[c.original] + 0 + "_" + i}
          onClick={() =>
            setActiveCharacterIndex(i === activeCharacterIndex ? -1 : i)
          }
          style={{ cursor: cursorStyle }}
          $underlined={true}
        >
          {!lacksPronunciations && (
            <Meaning $center>
              {c.pronunciation ? c.pronunciation : "\u00a0"}
            </Meaning>
          )}
          <Span $isBig={activeCharacterIndex === i}>{c.original}</Span>
        </GridItem>
      ))}
      {morphemes.slice(1).map((m, i) =>
        m
          .filter((c, i, a) => c !== "." && a.indexOf(c) === i)
          .map((c, j) => (
            <GridItem
              key={i + "_" + j}
              $gridArea={morphemeToLetterMap[c] + (i + 1)}
              style={{ cursor: cursorStyle }}
              onClick={() =>
                onClickMorpheme && onClickMorpheme(originalToMorphemeMap[c])
              }
            >
              {c in originalToMorphemeMap && (
                <Column $gap={0}>
                  {showMorpheme
                    ? showMorpheme(originalToMorphemeMap[c])
                    : defaultShowMorpheme(originalToMorphemeMap[c])}
                </Column>
              )}
            </GridItem>
          )),
      )}
      {morphemes
        .flatMap((a, i) => a.map((m, j) => (m === "." ? grid[i][j] : "")))
        .filter((c, i, a) => c && a.indexOf(c) === i)
        .map((c, i) => (
          <GridItem
            key={i}
            $gridArea={c}
            style={{ cursor: cursorStyle, pointerEvents: "none" }}
            // the style is needed to make this element transparent to click
          />
        ))}
    </Grid>
  );
}
