import styled from "styled-components";

export default styled.main`
  --action-blue: rgb(0, 145, 255);

  background-color: rgba(32, 32, 32, 0.9);
  box-sizing: border-box;
  max-width: 500px;
  margin: -40px auto 0;
  border-radius: 20px;
  padding: 20px;

  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: calc(100% - 2rem);
  max-height: calc(100% - 2rem);
  overflow: auto;

  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    text-align: center;
    margin: 20px 50px;

    & .value {
      color: var(--action-blue);
    }
  }

  & h3 {
    font-size: 22px;
  }

  & p {
    font-size: 18px;
    color: rgb(124, 123, 123);
    text-align: center;
    margin: 40px 50px;
  }

  & form {
    & .help {
      margin-bottom: 40px;
    }
    & .input-row {
      margin-top: 20px;

      & + .validation-message,
      & + .error-message {
        display: block;
        text-align: center;
        margin-top: 20px;
        font-size: 18px;
        color: rgb(255, 21, 0);
      }
    }
    & .button-bar {
      margin-top: 40px;
    }

    &
      input:not(
        [type="button"],
        [type="submit"],
        [type="reset"],
        [type="checkbox"],
        [type="radio"]
      ),
    & textarea {
      box-sizing: border-box;
      height: 64px;
      width: 100%;
      text-align: center;
      border-radius: 10px;
      background-color: rgba(76, 76, 76, 0.3);
      font-size: 22px;
      color: white;
      box-shadow: inset 1px 1px 0 rgba(0, 0, 0, 0.4);
      border: none;

      &::placeholder {
        color: rgb(124, 123, 123);
      }

      &:focus {
        border: 1px solid var(--action-blue);
        outline: none;
      }

      &:invalid {
        border: 1px solid rgb(255, 21, 0);
        outline: none;

        & + .validation-message {
          display: block;
          text-align: center;
          margin-top: 20px;
          font-size: 18px;
          color: rgb(255, 21, 0);
        }
      }

      &:valid {
        & + .validation-message {
          display: none;
          color: rgb(255, 21, 0);
        }
      }
    }

    & button:not(.flat),
    & input[type="button"],
    & input[type="submit"],
    & input[type="reset"] {
      box-sizing: border-box;
      height: 64px;
      text-align: center;
      border-radius: 10px;
      font-size: 18px;
      text-transform: uppercase;
      color: white;
      background: transparent;
      border: 1px solid rgba(151, 151, 151, 0.8);

      &[type="submit"],
      &[type="button"],
      &.default {
        border: none;
        background-color: var(--action-blue);
      }

      &.disabled {
        background-color: rgba(151, 151, 151, 0.8);
        cursor: not-allowed;
      }
    }

    & button.flat {
      background-color: transparent;
      border: none;
      font-size: inherit;
      color: var(--action-blue);
      cursor: pointer;
    }

    & .button-bar {
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;

      & > * {
        flex: 1 0 auto;
      }

      & > :not(:last-child) {
        margin-right: 20px;
      }
    }
  }
`;
