import React from "react";
import styled from "styled-components";

const SpinnerContainer = styled.div`
  border: ${(props) => props.borderWidth || "4px"} solid #f3f3f3;
  border-top: ${(props) => props.borderWidth || "4px"} solid
    ${(props) => props.color || "#3498db"};
  border-radius: 50%;
  width: ${(props) => props.size || "20px"};
  height: ${(props) => props.size || "20px"};
  animation: spin ${(props) => props.duration || "2s"} linear infinite;
  margin: ${(props) => props.margin || "10px auto"};

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export default function Spinner({
  size,
  color,
  borderWidth,
  duration,
  margin,
  className,
  style,
}) {
  return (
    <SpinnerContainer
      size={size}
      color={color}
      borderWidth={borderWidth}
      duration={duration}
      margin={margin}
      className={className}
      style={style}
    />
  );
}
