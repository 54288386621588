import React, { useState, useEffect, useCallback, useRef } from "react";
import Popup from "./Popup.js";
import IconBlueLink from "../../assets/icons/blue-link.svg";
import BreakdownButton from "../atoms/BreakdownButton.js";
import Loading from "../Loading.js";
import styled from "styled-components";
import { getJpdbCss } from "./JpdbCss.js";

const CenterColumn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

// Note: the `calc`s below should be slightly smaller than the requested size
// for `src/components/atoms/Card.js` with $standAlone = true. This prevents the
// popup from resizing when the content loads.
const JPDBWrapper = styled.div`
  box-sizing: content-box;
  width: calc(min(800px, 90dvh) - 2rem);
  height: calc(90dvh - 2rem - 50px);
  overflow-y: auto;
  overflow-x: hidden;
  border: none;
  background-color: #181818;
  ${getJpdbCss()}
`;

export default function JpdbPopup({ jmdictId, word, closePopups }) {
  const [currentPage, setCurrentPage] = useState({ jmdictId, word });
  const [content, setContent] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  // This prevents multiple audio files from playing at once.
  const isPlayingRef = useRef(false);

  useEffect(() => {
    // Note: we're assuming that there's only one JPDB popup at a time.
    // Calls to these two global functions are how we navigate or play audio.
    window.jpdbSetPage = (jmdictId, word) => {
      setCurrentPage({ jmdictId, word });
    };
    window.jpdbPlayAudio = async (key) => {
      if (isPlayingRef.current) return; // Double tap shouldn't make an echo.
      isPlayingRef.current = true;
      try {
        const audioUrl = `/jpdb/audio?key=${key}`;
        const response = await fetch(audioUrl);
        const audioBuffer = await response.arrayBuffer();
        const audioBlob = new Blob([audioBuffer], { type: "audio/mpeg" });
        const audioElement = document.createElement("audio");
        audioElement.src = URL.createObjectURL(audioBlob);
        audioElement.onended = () => {
          URL.revokeObjectURL(audioElement.src);
          isPlayingRef.current = false;
        };
        await audioElement.play();
      } catch (error) {
        console.error(error);
        isPlayingRef.current = false;
      }
    };
    return () => {
      window.jpdbSetPage = null;
      window.jpdbPlayAudio = null;
    };
  }, []);

  const fetchContent = useCallback(() => {
    setIsLoading(true);
    setContent("");
    const encodedWord = encodeURIComponent(currentPage.word);
    const internalURL = `/jpdb/vocab_html?jmdictId=${currentPage.jmdictId}&word=${encodedWord}`;
    fetch(internalURL).then(
      async (response) => {
        setContent(await response.text());
        setIsLoading(false);
      },
      (error) => {
        console.error(error);
        setIsLoading(false);
      },
    );
  }, [currentPage]);

  useEffect(() => {
    fetchContent();
  }, [fetchContent]);

  const encodedWord = encodeURIComponent(currentPage.word);
  const originalURL = `https://jpdb.io/vocabulary/${currentPage.jmdictId}/${encodedWord}`;

  return (
    <Popup
      title={
        <>
          {currentPage.word}
          <BreakdownButton
            onClick={() => {
              window.open(originalURL, "_blank", "noreferrer");
            }}
            className="breakdown-button"
            $padding="0.125rem 0.5rem"
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <img src={IconBlueLink} alt="JPDB" width={20} height={20} />
              JPDB
            </div>
          </BreakdownButton>
        </>
      }
      onClose={closePopups}
    >
      <CenterColumn>
        <JPDBWrapper>
          <div
            className="jpdb-page dark-mode"
            dangerouslySetInnerHTML={{ __html: content }}
          />
        </JPDBWrapper>
      </CenterColumn>
      {isLoading && <Loading />}
    </Popup>
  );
}
