import React, { useState, useEffect, useCallback, useRef } from "react";
// eslint-disable-next-line import/extensions,import/no-unresolved
import { Swiper, SwiperSlide } from "swiper/react";
// eslint-disable-next-line import/extensions,import/no-unresolved
import "swiper/css/bundle";
import { equal } from "fast-deep-equal";
import styled from "styled-components";
import { VIDEO_ASPECT_RATIO } from "../layout/VideoBackground.js";
import BreakdownOverlay from "../breakdown/BreakdownOverlay.js";
import PopoverManager from "../breakdown/phrase/PopoverManager.js";
import Popup from "../breakdown/Popup.js";
import AnkiCreator from "../breakdown/anki/AnkiCreator.js";
import ChatPopup from "../breakdown/ChatPopup.js";
import AnkiDeckPopup from "../breakdown/anki/AnkiDeckPopup.js";
import FeedbackPopup from "../breakdown/FeedbackPopup.js";
import JpdbPopup from "../breakdown/JpdbPopup.js";
import SeekBar from "./SeekBar.js";

const Container = styled.div`
  overflow: hidden;
`;

const FlexWrapper = styled.div`
  display: flex;
  height: 100dvh;
`;

const FlexGrower = styled.div`
  flex-grow: 1;
`;

function Scene({
  pause,
  togglePlay,
  episode,
  fragment,
  subtitle,
  sceneBoundaries,
  index,
  posterUrls,
  isActiveScene,
  popoverState,
  setPopoverState,
  popupState,
  setPopupState,
  subtitlesVisible,
  setSubtitlesVisible,
  isSeekBarVisible,
  setIsSeekBarVisible,
  createCardCallback,
  breakdownMenuOptions,
  getWordBreakdownKnowledgeState,
  setWordBreakdownKnowledgeState,
  popoverHasScrollbars,
  setPopoverHasScrollbars,
  slidePrev,
  slideNext,
  seekToScene,
  goBack,
}) {
  const sceneRef = useRef(null);
  const breakdownOverlayRef = useRef(null);

  const [swiper, setSwiper] = useState();

  const [videoHeight, setVideoHeight] = useState(0);
  const [emptySpace, setEmptySpace] = useState(0);
  const [breakdownScroll, setBreakdownScroll] = useState(0);
  const [popoverButtons, setPopoverButtons] = useState({});
  const [breakdownMoving, setBreakdownMoving] = useState(false);

  const videoPath = `/video/${episode.path}/${fragment}.webm`;
  const videoFramePath = `/video/${episode.path}/${fragment}_first.webp`;

  const registerPopoverButton = useCallback(
    (phraseIndex, button) => {
      setPopoverButtons((prev) => ({ ...prev, [phraseIndex]: button }));
    },
    [setPopoverButtons],
  );
  const getPopoverButton = useCallback(
    (phraseIndex) => popoverButtons[phraseIndex],
    [popoverButtons],
  );

  function closePopups() {
    setPopupState(null);
  }

  const calculateVideoDimensions = useCallback(() => {
    const pageWidth = Math.max(
      document.documentElement.clientWidth || 0,
      window.innerWidth || 0,
    );
    const pageHeight = Math.max(
      document.documentElement.clientHeight || 0,
      window.innerHeight || 0,
    );
    const videoWidth = pageWidth;
    const videoHeight =
      (videoWidth * VIDEO_ASPECT_RATIO.height) / VIDEO_ASPECT_RATIO.width;
    setVideoHeight(Math.min(videoHeight, pageHeight));
    const emptySpacePx = Math.max(0, pageHeight - videoHeight);
    const emptySpaceVh = (emptySpacePx / pageHeight) * 100;
    setEmptySpace(emptySpaceVh);
  }, []);

  useEffect(() => {
    window.addEventListener("resize", calculateVideoDimensions);
    window.addEventListener("orientationchange", calculateVideoDimensions);
    calculateVideoDimensions();
    return () => {
      window.removeEventListener("resize", calculateVideoDimensions);
      window.removeEventListener("orientationchange", calculateVideoDimensions);
    };
  }, [calculateVideoDimensions]);

  // show/hide based on subtitlesVisible
  useEffect(() => {
    if (swiper) {
      if (subtitlesVisible) {
        swiper.slideTo(1);
      } else {
        swiper.slideTo(0);
      }
    }
  }, [subtitlesVisible, swiper]);

  // Keyboard shortcuts
  useEffect(() => {
    function onKeyDown(e) {
      if (!isActiveScene || popupState) {
        return;
      }

      switch (e.code) {
        case "Space":
          e.preventDefault();
          togglePlay();
          break;
        case "ArrowUp":
          e.preventDefault();
          setSubtitlesVisible(true);
          break;
        case "ArrowDown":
          e.preventDefault();
          setSubtitlesVisible(false);
          break;
        case "ArrowLeft":
          e.preventDefault();
          goBack();
          break;
        case "ArrowRight":
          e.preventDefault();
          slideNext();
          break;
        default:
          break;
      }
    }

    document.addEventListener("keydown", onKeyDown);

    return () => {
      document.removeEventListener("keydown", onKeyDown);
    };
  }, [
    isActiveScene,
    togglePlay,
    popupState,
    slideNext,
    slidePrev,
    setSubtitlesVisible,
    goBack,
  ]);

  const updateSeekBarBottom = useCallback(() => {
    if (!isActiveScene) return;
    const pageWidth = Math.max(
      document.documentElement.clientWidth || 0,
      window.innerWidth || 0,
    );
    const videoWidth = pageWidth;
    const videoHeight =
      (videoWidth * VIDEO_ASPECT_RATIO.height) / VIDEO_ASPECT_RATIO.width;
    let seekBarBottom = videoHeight;
    if (breakdownOverlayRef.current) {
      const rect = breakdownOverlayRef.current.getBoundingClientRect();
      seekBarBottom = Math.min(rect.top, videoHeight);
    }
    if (sceneRef.current) {
      sceneRef.current.style.setProperty(
        "--seek-bar-bottom",
        `${seekBarBottom}px`,
      );
    }
  }, [isActiveScene]);

  useEffect(() => {
    if (isActiveScene) {
      updateSeekBarBottom();
    }
  }, [isActiveScene, updateSeekBarBottom]);

  useEffect(() => {
    if (breakdownMoving) {
      const interval = setInterval(() => {
        updateSeekBarBottom();
      }, 5);
      return () => clearInterval(interval);
    }
  }, [breakdownMoving, updateSeekBarBottom]);

  // Don't allow the video to play if there's a popup or popover open (it's
  // distracting, and auto-advance could trigger while interacting with the
  // popup)
  useEffect(() => {
    if (isActiveScene && (popupState || popoverState)) {
      pause();
    }
  }, [isActiveScene, popupState, popoverState, pause]);

  const handleSceneClick = useCallback(
    (e) => {
      const isPopoverDismissal = e?.nativeEvent?.isPopoverDismissal;
      if (!isPopoverDismissal) {
        togglePlay();
      }
      e.stopPropagation();
    },
    [togglePlay],
  );

  return (
    <Container ref={sceneRef} onClick={handleSceneClick}>
      {popupState?.popup === "anki" && isActiveScene && (
        <Popup title="Create Anki Card" onClose={closePopups}>
          <AnkiCreator
            videoPath={videoPath}
            posterPath={videoFramePath}
            posterBlobUrl={posterUrls?.[index]}
            episode={episode}
            parentBreakdown={subtitle}
            childBreakdown={popupState.breakdown}
            morpheme={popupState.morpheme}
            sceneNr={index}
            closeCallback={closePopups}
            createCallback={() => createCardCallback(index)}
            setWordBreakdownKnowledgeState={setWordBreakdownKnowledgeState}
          />
        </Popup>
      )}
      {popupState?.popup === "deck" && isActiveScene && (
        <Popup title="Anki Deck for this Scene" onClose={closePopups}>
          <AnkiDeckPopup movieId={episode.id} breakdownIndex={subtitle.index} />
        </Popup>
      )}
      {popupState?.popup === "feedback" && isActiveScene && (
        <Popup title="Feedback" onClose={closePopups}>
          <FeedbackPopup
            episode={episode}
            closePopup={closePopups}
            boundaries={sceneBoundaries?.[index]}
            sentenceBreakdown={subtitle}
            wordBreakdown={popupState?.wordBreakdown}
            morphemeBreakdown={popupState?.morphemeBreakdown}
          />
        </Popup>
      )}
      {popupState?.popup === "jpdb" && isActiveScene && (
        <JpdbPopup
          jmdictId={popupState.jmdictId}
          word={popupState.word}
          closePopups={closePopups}
        />
      )}
      {popupState?.popup === "chat" && isActiveScene && (
        <ChatPopup
          episode={episode}
          videoPath={videoPath}
          parentBreakdown={subtitle}
          childBreakdown={popupState.breakdown}
          setPopupState={setPopupState}
        />
      )}
      <Swiper
        style={{ height: "100dvh" }} // Swiper bug: https://github.com/nolimits4web/swiper/issues/3599
        direction="vertical"
        keyboard={{ enabled: false }}
        threshold={50}
        onSwiper={setSwiper}
        onSetTranslate={() => {
          setBreakdownMoving(true);
          updateSeekBarBottom();
        }}
        onTransitionEnd={() => {
          setBreakdownMoving(false);
          updateSeekBarBottom();
        }}
        initialSlide={subtitlesVisible ? 1 : 0}
        onSlideChange={(swiper) => {
          if (isActiveScene) {
            setSubtitlesVisible(swiper.activeIndex === 1);
          }
        }}
        allowSlideNext={!popoverHasScrollbars}
        allowSlidePrev={!popoverHasScrollbars}
      >
        <SwiperSlide>
          <div style={{ height: "100dvh" }} />
        </SwiperSlide>
        <SwiperSlide>
          <FlexWrapper>
            <FlexGrower></FlexGrower>
            <BreakdownOverlay
              overlayRef={breakdownOverlayRef}
              episode={episode}
              breakdown={subtitle}
              breakdownMenuOptions={breakdownMenuOptions}
              isVisible={true}
              animation={{}}
              preferredHeight={emptySpace}
              videoHeight={videoHeight}
              getWordBreakdownKnowledgeState={getWordBreakdownKnowledgeState}
              setWordBreakdownKnowledgeState={setWordBreakdownKnowledgeState}
              setBreakdownScroll={setBreakdownScroll}
              popoverState={popoverState}
              setPopoverState={setPopoverState}
              popupState={popupState}
              setPopupState={setPopupState}
              registerPopoverButton={registerPopoverButton}
              popoverHasScrollbars={popoverHasScrollbars}
              isSeekBarVisible={isSeekBarVisible}
              setIsSeekBarVisible={setIsSeekBarVisible}
            />
          </FlexWrapper>
        </SwiperSlide>
      </Swiper>
      {isSeekBarVisible && (
        <div
          style={{
            position: "absolute",
            top: "calc(var(--seek-bar-bottom) - 30px)",
            height: "30px",
            width: "100%",
          }}
        >
          <SeekBar
            currentTime={sceneBoundaries[index].start}
            sceneBoundaries={sceneBoundaries}
            posterUrls={posterUrls}
            onSeekStart={pause}
            seekToScene={seekToScene}
          />
        </div>
      )}
      {isActiveScene && (
        <PopoverManager
          popoverState={popoverState}
          setPopoverState={setPopoverState}
          popupState={popupState}
          setPopupState={setPopupState}
          breakdown={subtitle}
          getPopoverButton={getPopoverButton}
          getWordBreakdownKnowledgeState={getWordBreakdownKnowledgeState}
          setWordBreakdownKnowledgeState={setWordBreakdownKnowledgeState}
          breakdownScroll={breakdownScroll}
          setPopoverHasScrollbars={setPopoverHasScrollbars}
        />
      )}
    </Container>
  );
}

export default React.memo(Scene, equal);
