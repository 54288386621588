import React from "react";
import styled from "styled-components";
import Card from "../atoms/Card.js";
import Dropdown from "./Dropdown.js";

const PopupContainer = styled.div`
  z-index: 40;

  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PopupHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-left: 1rem;
  margin-right: 1rem;
`;

const PopupHeader = styled.h1`
  font-size: 1.625rem;
  font-weight: 600;
  margin-bottom: 0.7rem;
  color: inherit;
  text-align: center;
  flex-grow: 1;
`;

const FadedBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100dvw;
  height: 100dvh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 4;
`;

const CloseButton = styled.button`
  &.close-button {
    position: absolute;
    right: 0rem;
    top: 0rem;
    margin-top: -0.25rem;
    margin-right: 0.5rem;
    padding: 0rem; /* Needed on iOS */
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 2rem;
    color: #666;
    outline: none;
  }
`;

export default function Popup({
  title,
  children,
  onClose,
  contextMenuOptions = [],
  topLeft = null,
}) {
  return (
    <>
      <FadedBackground />
      <PopupContainer onClick={onClose}>
        <Card $standAlone onClick={(e) => e.stopPropagation()}>
          <CloseButton className="close-button" onClick={onClose}>
            &times;
          </CloseButton>
          <PopupHeaderContainer>
            {!!contextMenuOptions.length &&
              contextMenuOptions.some((e) => e) && (
                <Dropdown options={contextMenuOptions} />
              )}
            {topLeft}
            <PopupHeader>{title}</PopupHeader>
          </PopupHeaderContainer>
          {children}
        </Card>
      </PopupContainer>
    </>
  );
}
