import React, { useCallback, useContext, useEffect, useState } from "react";
import { SettingsContext } from "../../ContextProvider.js";
import { getSetting } from "../../Settings.js";
import styled from "styled-components";
import {
  logEvent,
  postRequest,
  isKanji,
  debounce,
} from "lingoflix-shared/src/utils.js";
import {
  getAnkiCSS,
  renderRubyWord,
  renderKanjis,
  renderPoster,
  renderSentence,
  renderAudio,
  renderSentenceEnTranslation,
} from "lingoflix-shared/src/anki.js";
import Grid from "../../layout/Grid.js";
import Row from "../../layout/Row.js";
import Column from "../../layout/Column.js";
import BreakdownButton from "../../atoms/BreakdownButton.js";
import Dropdown from "../../breakdown/Dropdown.js";
import AutoGrowTextArea from "../../atoms/AutoGrowTextArea.js";
import { toast } from "react-toastify";
import IconTrashCan from "../../../assets/icons/trash-can.svg";
import IconPlus from "../../../assets/icons/plus.svg";
import Spinner from "../../atoms/Spinner.js";
import IconPencil from "../../../assets/icons/pencil.svg";
import IconThumbsUp from "../../../assets/icons/thumbs-up.svg";
import IconThumbsDown from "../../../assets/icons/thumbs-down.svg";

function RawHtml({ html }) {
  return <div dangerouslySetInnerHTML={{ __html: html }} />;
}

function alterElement(list, i, f) {
  const newList = list.slice();
  newList[i] = f(newList[i]);
  return newList;
}

const AnkiFormWrapper = styled.form`
  ${getAnkiCSS()}

  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  overflow: auto;
  padding-right: 0.5rem; // For scrolling bar positioning
  padding-left: 0.5rem; // For symmetry

  .fixed-size {
    flex: 0 0 auto;
  }
  .variable-size {
    flex: 1 1 auto;
  }
`;

const FormContents = styled.div`
  overflow: auto;
  flex-grow: 1;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  padding-right: 0.5rem; // for scrolling bar positioning
  padding-left: 0.5rem; // For symmetry
`;

const PaddingBottom = styled.div`
  padding-bottom: 1.5rem;
`;

const CustomFieldRow = styled(Row)`
  align-items: flex-start;
  width: 100%;
`;

export default function AnkiForm({
  videoPath,
  wordAudioPath,
  posterBlobUrl,
  episode,
  isMorpheme,
  original,
  rubyBreakdown,
  wordRubyBreakdown,
  romaji,
  kanjis,
  translations,
  jmdictId,
  jmdictText,
  jmdictRubyBreakdown,
  wordRank,
  jmdictWordRank,

  morphemeOriginal,
  morphemeTranslation,
  morphemeBreakdown,
  wordBreakdown,
  wordOriginal,
  wordPronunciation,
  wordTranslation,
  lineSelection,
  sentence,
  sentenceBreakdown,
  sentenceOriginal,
  sentenceTranslation,

  cardFront,
  cardBack,
  sentenceElements,
  cardCustomFields,

  closeCallback,
  submitCallback,

  autoGenerateMnemonic,
}) {
  const [settings] = useContext(SettingsContext);

  function onClose(e) {
    e.preventDefault();
    closeCallback();
  }

  const translation = translations.length > 0 ? translations[0] : "";
  const hasKanji = original.split("").some(isKanji);
  const [front, setFront] = useState(cardFront);
  const [back, setBack] = useState(cardBack);
  const customFieldOptions = [
    {
      text: "Add Translation Mnemonic",
      backend: "translation_mnemonic",
      amount: 3,
    },
    hasKanji && {
      text: "Add Kanji Meanings Mnemonic",
      backend: "kanji_meanings_mnemonic",
      amount: 1,
    },
    { text: "Add Explanation", backend: "explanation", amount: 1 },
    translations &&
      translations.length >= 2 && {
        text: isMorpheme ? "Add Context Word" : "Add All Translations",
        action: isMorpheme ? "context_word" : "all_translations",
        amount: 1,
      },
    { text: "Add Example Sentence", backend: "example_sentence", amount: 1 },
    { text: "Add Custom Field", amount: 1 },
  ]
    .filter((o) => !!o)
    .map((o, i) => ({ ...o, index: i }));

  const [customFields, setCustomFields] = useState(
    cardCustomFields.map((f) => {
      return {
        ...f,
        content: (f.content || []).map((c) =>
          !c
            ? {
                text: "",
                isLoading: false,
              }
            : typeof c === "string" // Handle legacy save states.
            ? {
                text: c,
                isLoading: false,
              }
            : {
                text: c.text || "",
                isLoading: false,
                ...c,
              },
        ),
      };
    }),
  );
  const alterCustomField = useCallback((index, fn) => {
    setCustomFields((customFields) =>
      customFields.map((f) => (f.index === index ? fn(f) : f)),
    );
  }, []);
  const alterCustomFieldContent = useCallback(
    (index, choice, fn) => {
      alterCustomField(index, (f) => ({
        ...f,
        content: alterElement(f.content, choice, fn),
      }));
    },
    [alterCustomField],
  );

  const [editingField, setEditingField] = useState(null);

  const debouncedSaveToBackend = useCallback(
    debounce((data) => {
      postRequest("/api/anki-draft/update", data, () => {}, true).catch(() => {
        toast.error("Failed to save draft of Anki card.");
      });
    }, 1000),
    [],
  );

  useEffect(() => {
    debouncedSaveToBackend({
      word: wordOriginal,
      sentence: sentenceOriginal,
      morpheme: morphemeOriginal || "",
      data: { front, back, customFields },
    });
  }, [
    front,
    back,
    customFields,
    wordOriginal,
    sentenceOriginal,
    morphemeOriginal,
    debouncedSaveToBackend,
  ]);

  const handleSubmit = (e) => {
    e.preventDefault();
    logEvent("click-save-anki");
    const data = {
      videoPath,
      episodeId: episode.id,
      jmdMeaning:
        translations && translations.length > 0 ? translations[0] : "",
      rubyBreakdown: rubyBreakdown,
      wordRubyBreakdown: wordRubyBreakdown,
      original: original,
      romaji: romaji,
      translation: translation,
      customFields: customFields.map((field) => ({
        title: field.title,
        content: field.content[field.selectedOption].text,
      })),
      sentenceSpans: sentenceElements,
      jmdictId,
      jmdictText,
      jmdictRubyBreakdown,
      wordRank,
      jmdictWordRank,

      cardBack: back,
      cardFront: front,

      morphemeOriginal,
      morphemeTranslation,
      wordOriginal,
      wordPronunciation,
      wordTranslation,
      sentenceOriginal,
      sentenceTranslation,
      lineSelection,
    };
    submitCallback(data, closeCallback);
  };

  const constructCustomContent = (action) => {
    if (action === "all_translations") {
      return translations.join(", ");
    } else if (action === "context_word") {
      return (
        "This Anki card was inspired by " +
          morphemeOriginal +
          " (interpreted as '" +
          morphemeTranslation +
          "') in the word " +
          wordOriginal +
          " (" +
          wordPronunciation +
          ") which means '" +
          wordTranslation +
          "' in the sentence " +
          sentenceOriginal,
        "."
      );
    }
    return "";
  };

  const doBackendRequest = (option, index, choice, language = null) => {
    const startLoading = () => {
      alterCustomFieldContent(index, choice, (x) => ({
        ...x,
        text: "",
        isLoading: true,
      }));
    };
    const doneLoading = () => {
      alterCustomFieldContent(index, choice, (x) => ({
        ...x,
        isLoading: false,
      }));
    };
    startLoading();

    const payload = {
      sentence,
      word: wordOriginal,
      wordTranslation: wordTranslation,
    };
    const languages = getSetting(settings, "languages");
    if (
      languages &&
      languages.length > 1 &&
      !(languages.length === 1 && languages[0] === "English")
    ) {
      payload.languages = language ? [language] : languages;
    }
    postRequest(
      "/api/anki-chat/" + option,
      payload,
      (response) => {
        const reader = response.body.getReader();
        return reader.read().then(function processResult(result) {
          if (result.done) {
            doneLoading();
            return;
          }
          const message = new TextDecoder().decode(result.value);
          alterCustomFieldContent(index, choice, (x) => {
            if (!x) {
              return { text: message, isLoading: false };
            }
            return {
              ...x,
              text: (x.text ?? "") + message,
            };
          });
          return reader.read().then(processResult);
        });
      },
      true,
    ).catch((e) => {
      console.error(e);
      toast("Failed to fetch data for custom field.");
      doneLoading();
    });
  };

  const removeCustomField = (field) => {
    setCustomFields((customFields) =>
      customFields.filter((f) => f.index !== field.index),
    );
  };

  const removeCustomFieldContent = (fieldIndex, contentIndex) => {
    alterCustomField(fieldIndex, (f) => ({
      ...f,
      content: f.content.filter((_, i) => i !== contentIndex),
      selectedOption:
        f.selectedOption === -1 ||
        (f.selectedOption == contentIndex && f.content.length > 1)
          ? -1 // If no choice was made or we deleted our choice and there's more than one option left, force the user to make a choice.
          : f.selectedOption >= contentIndex
          ? Math.max(f.selectedOption - 1, 0)
          : f.selectedOption,
    }));
  };

  const selectCustomFieldOption = (fieldIndex, optionIndex) => {
    if (editingField !== `${fieldIndex}_${optionIndex}`) {
      setEditingField(null);
    }

    // if this is already selected, don't do anything
    const currentSetting = customFields.find((f) => f.index === fieldIndex);
    if (currentSetting.selectedOption === optionIndex) return;
    alterCustomField(fieldIndex, (f) => ({
      ...f,
      selectedOption: optionIndex,
    }));
  };

  const createCustomField = (option) => {
    const index =
      customFields.map((f) => f.index).reduce((a, b) => Math.max(a, b), 0) + 1;

    let contentAmount = option.amount;
    let languages = getSetting(settings, "languages") || [];

    if (
      option.backend &&
      option.backend.includes("mnemonic") &&
      languages.length > 1
    ) {
      contentAmount = languages.length + 1;
    }

    setCustomFields([
      ...customFields,
      {
        index,
        title: option.text.replace("Add ", ""),
        content: Array(contentAmount)
          .fill()
          .map((_, i) => ({
            text: option.action ? constructCustomContent(option.action, i) : "",
            isLoading: false,
          })),
        selectedOption: contentAmount == 1 ? 0 : -1,
        // Note: The -1 means we force the user to make a choice before submitting the form.
        option,
      },
    ]);
    if (option.backend) {
      if (option.backend.includes("mnemonic") && languages.length > 1) {
        languages.forEach((lang, i) => {
          doBackendRequest(option.backend, index, i, lang);
        });
        doBackendRequest(option.backend, index, languages.length);
      } else {
        for (let i = 0; i < contentAmount; i++) {
          doBackendRequest(option.backend, index, i);
        }
      }
    }
  };

  const addCustomFieldContent = (fieldIndex) => {
    alterCustomField(fieldIndex, (f) => ({
      ...f,
      content: [...f.content, ""],
    }));
    if (customFields.find((f) => f.index === fieldIndex).option.backend) {
      doBackendRequest(
        customFields.find((f) => f.index === fieldIndex).option.backend,
        fieldIndex,
        customFields.find((f) => f.index === fieldIndex).content.length,
      );
    }
  };

  // Autogenerate a translation mnemonic:
  useEffect(() => {
    const translationMnemonic = customFieldOptions.find(
      (o) => o.backend === "translation_mnemonic",
    );
    if (
      autoGenerateMnemonic &&
      translationMnemonic &&
      customFields.length === 0
    ) {
      createCustomField(translationMnemonic);
    }
  }, []);

  const showJmdictWord = jmdictRubyBreakdown;
  const showOriginalWord = rubyBreakdown && jmdictText != wordOriginal;

  const allCustomFieldsChosen = customFields.every((f) =>
    f.content.every((c) => c.selectedOption !== -1),
  );
  const customFieldsLoaded = customFields.every(
    (f) => f.selectedOption !== -1 && !f.content[f.selectedOption].isLoading,
  );
  const formIsSubmittable = allCustomFieldsChosen && customFieldsLoaded;

  const sendFeedback = (fieldIndex, optionIndex, rating) => {
    logEvent(`click-${rating.replace("_", "-")}-custom-field`);

    const field = customFields.find((f) => f.index === fieldIndex);
    const content = field.content[optionIndex];

    postRequest(
      "/api/feedback/mnemonic",
      {
        timeMicros: Date.now() * 1000,
        episodeId: episode.id,
        sentenceBreakdown: JSON.stringify(sentenceBreakdown),
        sentence: sentenceBreakdown?.original,
        sentenceBreakdownIndex: sentenceBreakdown?.index_start,
        wordBreakdown: JSON.stringify(wordBreakdown),
        word: wordBreakdown?.original,
        morphemeBreakdown: JSON.stringify(morphemeBreakdown),
        morpheme: morphemeBreakdown?.original,
        mnemonicRating: rating,
        mnemonicBackend: field.option.backend,
        mnemonicResult: content.text,
      },
      () => {
        toast.success("Thanks for the feedback!");
      },
      true,
    ).catch((e) => {
      console.error("Failed to save feedback", e);
      toast.error("Failed to save feedback");
    });
  };

  return (
    <AnkiFormWrapper onSubmit={handleSubmit}>
      <FormContents>
        <div className="card front">
          <AutoGrowTextArea
            value={front}
            onChange={(e) => setFront(e.target.value)}
            $boldText
            $centerText
            $padding="0"
            $fontSize="24px"
            $bgColor="#333"
            $fgColor="#f9f9f9"
            $placeholder="<Markdown for card front>"
            $placeholderColor="#999"
          />
        </div>
        <div className="card back">
          <AutoGrowTextArea
            value={back}
            onChange={(e) => setBack(e.target.value)}
            $centerText
            style={{ fontSize: "1rem" }}
            $bgColor="#333"
            $fgColor="#f9f9f9"
            $placeholder="<Markdown for card back>"
            $placeholderColor="#999"
          />
          {showJmdictWord && (
            <RawHtml
              html={renderRubyWord(jmdictRubyBreakdown, jmdictWordRank)}
            />
          )}
          {showOriginalWord && (
            <RawHtml html={renderRubyWord(rubyBreakdown, wordRank)} />
          )}
          {kanjis && kanjis.length > 0 && (
            <RawHtml html={renderKanjis(kanjis)} />
          )}
          {wordAudioPath && <RawHtml html={renderAudio(wordAudioPath)} />}
          {posterBlobUrl && <RawHtml html={renderPoster(posterBlobUrl)} />}
          <PaddingBottom>
            <RawHtml
              html={renderSentence(
                sentenceElements,
                wordRubyBreakdown,
                original,
              )}
            />
            <RawHtml
              html={renderAudio(videoPath.replace(/\.[^.]+$/, ".mp3"))}
            />
            <RawHtml html={renderSentenceEnTranslation(sentenceTranslation)} />
          </PaddingBottom>
          {customFields.length > 0 &&
            customFields.map((field, i) => (
              <div className="custom-field" key={i}>
                <CustomFieldRow $gap="0">
                  <div style={{ flex: 1 }}>
                    <Row>
                      <AutoGrowTextArea
                        value={field.title}
                        onChange={(e) =>
                          alterCustomField(field.index, (f) => ({
                            ...f,
                            title: e.target.value,
                          }))
                        }
                        $centerText
                        $bgColor="#2a2a2a"
                        $fgColor="#f0e68c"
                        minHeight="1rem"
                        $padding="0"
                        $fontSize="16px"
                        $boldText
                      />
                      <BreakdownButton
                        type="button"
                        className="breakdown-button"
                        onClick={() => removeCustomField(field)}
                        $fitContent
                        $noCapitalize
                        $padding="8px 10px"
                      >
                        <img src={IconTrashCan} width="16" height="16" />
                      </BreakdownButton>
                    </Row>
                    {field.content.map((content, index) => (
                      <div
                        key={index}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          position: "relative",
                        }}
                      >
                        {content.isLoading && (
                          <Spinner size="16px" className="fixed-size" />
                        )}
                        <AutoGrowTextArea
                          id={`field_${field.index}_${index}`}
                          value={content?.text}
                          readOnly={
                            editingField !== `${field.index}_${index}` ||
                            content.isLoading
                          }
                          onClick={() => {
                            const wasAlreadySelected =
                              field.selectedOption === index;
                            if (wasAlreadySelected) {
                              // Double click to edit:
                              setEditingField(`${field.index}_${index}`);
                              document
                                .getElementById(`field_${field.index}_${index}`)
                                .focus();
                            } else {
                              selectCustomFieldOption(field.index, index);
                            }
                          }}
                          onChange={(e) => {
                            alterCustomFieldContent(
                              field.index,
                              index,
                              (x) => ({
                                ...x,
                                text: e.target.value,
                                isLoading: false,
                              }),
                            );
                          }}
                          $centerText
                          style={{
                            flex: 1,
                            cursor: "pointer",
                          }}
                          $bgColor={
                            index === field.selectedOption
                              ? "#444444"
                              : "#2a2a2a"
                          }
                          $fgColor="#b0c4de"
                          $padding="0"
                          $fontSize="14px"
                          className="variable-size"
                        />
                        <Dropdown
                          direction="right"
                          options={[
                            {
                              label: (
                                <Row $gap="8px" $alignItems="center">
                                  <img
                                    src={IconPencil}
                                    width="16"
                                    height="16"
                                  />
                                  <span>Edit</span>
                                </Row>
                              ),
                              action: () => {
                                selectCustomFieldOption(field.index, index);
                                setEditingField(`${field.index}_${index}`);
                                document
                                  .getElementById(
                                    `field_${field.index}_${index}`,
                                  )
                                  .focus();
                              },
                            },
                            {
                              label: (
                                <Row $gap="8px" $alignItems="center">
                                  <img
                                    src={IconThumbsUp}
                                    width="16"
                                    height="16"
                                  />
                                  <span>Helpful</span>
                                </Row>
                              ),
                              action: () => {
                                sendFeedback(field.index, index, "thumbs_up");
                              },
                            },
                            {
                              label: (
                                <Row $gap="8px" $alignItems="center">
                                  <img
                                    src={IconThumbsDown}
                                    width="16"
                                    height="16"
                                  />
                                  <span>Not Helpful</span>
                                </Row>
                              ),
                              action: () => {
                                sendFeedback(field.index, index, "thumbs_down");
                              },
                            },
                            {
                              label: (
                                <Row $gap="8px" $alignItems="center">
                                  <img
                                    src={IconTrashCan}
                                    width="16"
                                    height="16"
                                    style={{
                                      opacity:
                                        field.content.length === 1 ? 0.5 : 1,
                                    }}
                                  />
                                  <span>Delete</span>
                                </Row>
                              ),
                              action: () =>
                                removeCustomFieldContent(field.index, index),
                              enabled: field.content.length > 1,
                            },
                          ].filter(Boolean)}
                          buttonFontSize={16}
                          buttonVerticalPadding={8}
                          buttonHorizontalPadding={10}
                        />
                      </div>
                    ))}
                    {field.option.backend && (
                      <BreakdownButton
                        type="button"
                        className="breakdown-button"
                        onClick={() => addCustomFieldContent(field.index)}
                        $fitContent
                        $noCapitalize
                        style={{ width: "100%", marginTop: "0.5rem" }}
                      >
                        <img src={IconPlus} width="16" height="16" />
                      </BreakdownButton>
                    )}
                  </div>
                </CustomFieldRow>
              </div>
            ))}
          <Column $center $gap="0.25rem">
            {customFieldOptions
              .filter(
                (o) =>
                  !o.backend ||
                  !customFields.some((f) => f.option.backend == o.backend),
              )
              .map((option, i) => (
                <BreakdownButton
                  key={i}
                  className="breakdown-button"
                  type="button"
                  onClick={() => createCustomField(option)}
                  $fitContent
                  $noCapitalize
                  $fontSize=".75rem"
                  $padding="0.25rem 0.25rem"
                >
                  {option.text}
                </BreakdownButton>
              ))}
          </Column>
        </div>
      </FormContents>
      <Grid>
        <BreakdownButton className="breakdown-button" onClick={onClose}>
          cancel
        </BreakdownButton>
        <BreakdownButton
          className="breakdown-button"
          type="submit"
          $primary
          $greyOut={!formIsSubmittable}
          onClick={(e) => {
            if (!formIsSubmittable) {
              e.preventDefault();
              const unChosen = customFields.filter(
                (f) => f.selectedOption === -1,
              );
              if (unChosen.length > 0) {
                toast.error(
                  "Please make selections for: " +
                    unChosen.map((f) => f.title).join(", "),
                );
              } else {
                const loading = customFields.filter(
                  (f) => f.content[f.selectedOption].isLoading,
                );
                if (loading.length > 0) {
                  toast.error(
                    "Please wait for " +
                      loading.map((f) => f.title).join(", ") +
                      " to finish generating.",
                  );
                } else {
                  const msg = "Form could not be submitted; error unknown.";
                  console.error(msg);
                  toast.error(msg);
                }
              }
            }
          }}
        >
          Save
        </BreakdownButton>
      </Grid>
    </AnkiFormWrapper>
  );
}
